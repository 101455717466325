import React from 'react';
import { Data } from '../../Data/Services/LearnMore.json';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import BG from '../../Images/BG.png';
import BG1 from '../../Images/BG1.png';
import button from '../../Images/button.png';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  div: {
    backgroundImage: isMobile ? `url(${BG1})` : `url(${BG})`,
    backgroundColor: '#4B4B4B',
    backgroundBlendMode: 'multiply',
    backgroundRepeat: '',
    backgroundSize: 'cover',
  },
  Text1: {
    color: 'White',
    fontSize: isMobile ? '7vw' : '2.8vw',
    fontWeight: '900',
    textAlign: 'Left',
    fontFamily: 'Gothic A1',
    marginTop: isMobile ? '7vw' : '1vw',
    maxWidth: isMobile ? '55vw' : '60vw',
    marginLeft: isMobile ? '-11vw' : '',
  },
  Text2: {
    color: 'White',
    fontSize: isMobile ? '4vw' : '1.2vw',
    fontWeight: 'normal',
    textAlign: 'left',
    fontFamily: 'Gothic A1',
    marginTop: isMobile ? '2vw' : '-1.5vw',
    maxWidth: isMobile ? '57vw' : '39vw',
    marginLeft: isMobile ? '-11vw' : '',
    marginBottom: isMobile ? '17vw' : '',
  },
  table: {
    marginLeft: isMobile ? '13vw' : '12.5vw',
    height: isMobile ? '80vwvw' : '10.65vw',
  },

  img: {
    cursor: 'pointer',
    // height: isMobile ? '10vw' : '4vw',
    width: isMobile ? '10vw' : '4vw',
    marginLeft: isMobile ? '25vw' : '6vw',
    // marginTop: isMobile ? '41vw' : '2.5vw',
  },
});

export default function LearnMore() {
  const classes = useStyles();
  const history = useHistory();
  const redirect = () => {
    history.push('/products');
    window.scrollTo(0, 0);
  };

  function Web() {
    return (
      <div>
        {Data.map((post) => {
          return (
            <div>
              <table className={classes.table}>
                <tbody>
                  <tr>
                    <td>
                      <tr>
                        <p className={classes.Text1}>{post.Text1}</p>
                      </tr>
                      <tr>
                        <p className={classes.Text2}>{post.Text2}</p>
                      </tr>
                    </td>

                    <td>
                      <img
                        className={classes.img}
                        src={button}
                        onClick={() => {
                          history.push({
                            pathname: '/contactus',
                            state: 'contact2',
                          });
                        }}
                        alt={''}
                      ></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <div className={classes.div}>{Web()}</div>
    </div>
  );
}
