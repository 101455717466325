import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import { Typography } from '@material-ui/core';
import { header, body } from '../../Data/Products/GetInTouch.json';

import GetInTouchArrow from '../../Images/button.png';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    backgroundColor: '#4B4B4B',
    height: isMobile ? '79.710144927536232vw' : '12.625vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  header: {
    fontFamily: 'Gothic A1',
    fontWeight: 'bold',
    color: 'white',
    fontSize: isMobile ? '7.246376811594203vw' : '3.02vw',
    marginLeft: isMobile ? '3.623188405797101vw' : '12.34375vw',
    width: isMobile ? '64.975845410628019vw' : '40vw',
    textAlign: 'left',
  },
  body: {
    fontFamily: 'Gothic A1',
    fontWeight: 'normal',
    color: 'white',
    fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
    width: isMobile ? '72.463768115942029vw' : '45vw',
    textAlign: 'left',
    marginLeft: isMobile ? '3.623188405797101vw' : '12.34375vw',
    marginTop: isMobile ? '4.521739130434783vw' : '0',
  },
  arrow: {
    width: isMobile ? '10.144927536231884vw' : '3.541666666666667vw',
    marginLeft: isMobile ? '9.903381642512077vw' : '10vw',
    cursor: 'pointer',
  },
  upwards: {
    width: '14.340579710144928vw',
    height: '15.56280193236715vw',
    position: 'absolute',
    bottom: `-${15.56280193236715 / 2}vw`,
    left: `${50 - 14.340579710144928 / 2}vw`,
  },
}));

export default function GetInTouch(props) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <Typography className={classes.header}>{header}</Typography>
        <Typography className={classes.body}>{body}</Typography>
      </div>
      <img
        src={GetInTouchArrow}
        className={classes.arrow}
        alt=''
        onClick={() => {
          history.push({
            pathname: '/contactus',
            state: 'contact2',
          });
        }}
      />
    </div>
  );
}
