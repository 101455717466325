import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'
import SingleFeature from './SingleFeature'
import { Data as SingleLineData } from '../../Data/LiftMonitoring/SingleLineFeatures.json'
import { Data as FourLineData } from '../../Data/LiftMonitoring/FourLineFeatures.json'
import L4Product from '../../Images/pixelDevice4L.png'
import L1Product from '../../Images/pixeldevice.png'

const useStyles = makeStyles((theme) => ({
  container: {
    width: isMobile ? '93vw' : '50vw',
    margin: 'auto',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '3vw' : '1.2vw',
    padding: isMobile ? '3vw' : '2.6vw',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
  },
  featuresContainer: {
    height: isMobile ? '143vw' : '35vw',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  dot: {
    width: isMobile ? '3.8vw' : '1vw',
    margin: 'auto',
  },
  title: {
    fontWeight: '900',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '5.2vw' : '1.5vw',
    margin: isMobile ? '1vw 0vw' : '0vw',
    color: '#FA9E23',
    textTransform: 'uppercase',
    marginBottom: isMobile ? '5vw' : '3vw',
  },
  body: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.2vw' : '1.2vw',
    margin: '0vw',
    width: isMobile ? '44vw' : '20vw',
  },
  product: {
    width: isMobile ? '40vw' : '15vw',
  },
  imgDiv: {
    marginTop: isMobile ? '5vw' : '3vw',
    marginBottom: isMobile ? '5vw' : '0vw',
    marginRight: isMobile ? '0vw' : '2vw',
    marginLeft: isMobile ? '23vw' : '0vw',
  },
}))

export default function Features(props) {
  const classes = useStyles()

  const Data = props.product === 'SingleLine' ? SingleLineData : FourLineData
  return (
    <div className={classes.container}>
      <div className={classes.imgDiv}>
        <img
          className={classes.product}
          src={props.product === 'SingleLine' ? L1Product : L4Product}
          alt='elevator'
        />
      </div>
      <div className={classes.textDiv}>
        <h1 className={classes.title}>Features</h1>
        <div className={classes.featuresContainer}>
          {Data.map((elem, index) => (
            <SingleFeature number={index + 1} text={elem.text} />
          ))}
        </div>
      </div>
    </div>
  )
}
