import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'
import OrangeDotImage from '../../Images/Orange Dot.png'

const useStyles = makeStyles((theme) => ({
  container: {
    width: isMobile ? '45vw' : '25vw',
  },
  dot: {
    width: isMobile ? '3.8vw' : '1vw',
    margin: 'auto',
  },
  title: {
    fontWeight: '700',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.8vw' : '1.5vw',
    margin: isMobile ? '1vw 0vw' : '0vw',
  },
  body: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.2vw' : '1.2vw',
    margin: '0vw',
    width: isMobile ? '44vw' : '20vw',
  },
}))

export default function SingleReportEvent(props) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <table>
        <tbody>
          <tr>
            <td>
              <img className={classes.dot} src={OrangeDotImage} />
            </td>
          </tr>
          <tr>
            <td>
              <p className={classes.title}>{props.title}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className={classes.body}>{props.body}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
