import React from 'react'
import { Data } from '../../Data/LiftMonitoring/WirlessCommunicationGateway.json'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    color: '#FA9E23',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: isMobile ? '6.757vw' : '3vw',
  },
  content: {
    color: '#4B4B4B',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '5vw' : '1.667vw',
    paddingRight: isMobile ? '' : '3vw',
    paddingLeft: isMobile ? '' : '3vw',
  },
  center: {
    width: '100vw',
  },
  titleDiv: {
    width: isMobile ? '92vw' : '75vw',
    textAlign: 'center',
  },
  contentDiv: {
    width: isMobile ? '92vw' : '75vw',
    textAlign: 'center',
    marginTop: isMobile ? '-1vw' : '',
  },
})

export default function WirelessCommunicationGateway() {
  const styles = useStyles()
  return (
    <div className={styles.center}>
      <div className={styles.titleDiv}>
        <p className={styles.title}>{Data.title}</p>
      </div>
      <div className={styles.contentDiv}>
        <p className={styles.content}>{Data.content}</p>
      </div>
    </div>
  )
}
