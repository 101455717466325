import React from 'react'

import { isMobile } from 'react-device-detect'

import { makeStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'
import { useHistory } from 'react-router-dom'

import PlannedMaintenanceDesktop from '../../Images/PlannedMaintenanceDesktop.png'
import CommunicationDesktop from '../../Images/CommunicationDesktop.png'
import TechnicalAbilityDesktop from '../../Images/TechnicalAbilityDesktop.png'
import PlannedMaintenanceMobile from '../../Images/PlannedMaintenanceMobile.png'
import CommunicationMobile from '../../Images/CommunicationMobile.png'
import TechnicalAbilityMobile from '../../Images/TechnicalAbilityMobile.png'

import data from '../../Data/Services/QualityCards.json'

const useStyles = makeStyles({
  main: {},
  title: {
    fontFamily: 'Gothic A1',
    marginTop: '3.2vw',
    fontSize: isMobile ? '5vw' : '1.6vw',
    color: '#FA9E23',
    fontWeight: '800',
    width: '12.25vw',
    textAlign: 'left',
  },
  plannedCommImg: {
    marginLeft: isMobile ? '' : '-0.17vw',
    width: isMobile ? '92vw' : '17.7vw',
    height: isMobile ? '56.5vw' : '22.25vw',
    marginBottom: isMobile ? '4vw' : '',
  },
  techImg: {
    width: isMobile ? '92vw' : '17.7vw',
    height: isMobile ? '56.5vw' : '17.2vw',
    marginBottom: isMobile ? '4vw' : '',
  },
  content: {
    fontFamily: 'Gothic A1',
    width: '91vw',
    paddingLeft: isMobile ? '2vw' : '1.45vw',
    paddingRight: isMobile ? '2vw' : '',
  },
  content2: {
    fontFamily: 'Gothic A1',
    width: '88vw',
    paddingLeft: isMobile ? '2vw' : '1.45vw',
    paddingRight: isMobile ? '2vw' : '',
  },
  paragraph: {
    fontFamily: 'Gothic A1',

    width: isMobile ? '92w' : '55vw',
    fontSize: isMobile ? '4.3vw' : '1.25vw',
    color: '#4B4B4B',
    textAlign: 'left',
  },
})

export default function QualityCards() {
  const history = useHistory()

  const styles = useStyles()
  return isMobile ? (
    <div className={styles.main}>
      <div style={{ paddingBottom: '11vw' }}>
        <div>
          <img
            src={PlannedMaintenanceMobile}
            className={styles.plannedCommImg}
            alt=''
          />
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            {parse(data.plannedMaintenanceFirstParagraph)}
          </p>
        </div>
        <div className={styles.content2}>
          <p className={styles.paragraph}>
            {' '}
            {parse(data.plannedMaintenanceSecondParagraph)}{' '}
            <span
              style={{
                cursor: 'pointer',
                color: '#FA9E23',
                textDecoration: 'underline',
              }}
              onClick={() => {
                history.push({ pathname: '/contactus', state: 'contact2' })
              }}
            >
              <b>contacting us!</b>
            </span>
          </p>
        </div>
      </div>
      <div style={{ paddingBottom: '11vw' }}>
        <div>
          <img src={TechnicalAbilityMobile} className={styles.techImg} alt='' />
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            {' '}
            {parse(data.technicalAbilityParagraph)}{' '}
            <span
              style={{
                cursor: 'pointer',
                color: '#FA9E23',
                textDecoration: 'underline',
              }}
              onClick={() => {
                history.push({ pathname: '/contactus', state: 'contact2' })
              }}
            >
              <b>enquire here</b>
            </span>{' '}
            {data.technicalAbilityParagraph2}
          </p>
        </div>
      </div>
      <div>
        <div>
          <img
            src={CommunicationMobile}
            className={styles.plannedCommImg}
            alt=''
          />
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>{data.communicationFirstParagraph}</p>
        </div>
        <div className={styles.content2}>
          <p className={styles.paragraph}>
            {data.communicationSecondParagraph}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.main}>
      <table>
        <tbody>
          <tr>
            <td>
              <img
                src={PlannedMaintenanceDesktop}
                className={styles.plannedCommImg}
                alt=''
              />
            </td>
            <td>
              <div className={styles.content}>
                <p className={styles.title}>PLANNED MAINTENANCE </p>
                <p className={styles.paragraph}>
                  {parse(data.plannedMaintenanceFirstParagraph)}
                </p>
                <p className={styles.paragraph}>
                  {parse(data.plannedMaintenanceSecondParagraph)}
                  <span
                    style={{
                      cursor: 'pointer',
                      color: '#FA9E23',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      history.push({
                        pathname: '/contactus',
                        state: 'contact2',
                      })
                    }}
                  >
                    <b>contacting us!</b>
                  </span>
                </p>
              </div>
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <img
                src={TechnicalAbilityDesktop}
                className={styles.techImg}
                alt=''
              />
            </td>
            <td>
              <div className={styles.content}>
                <p className={styles.title}>TECHNICAL ABILITY</p>
                <p className={styles.paragraph}>
                  {parse(data.technicalAbilityParagraph)}{' '}
                  <span
                    style={{
                      cursor: 'pointer',
                      color: '#FA9E23',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      history.push({
                        pathname: '/contactus',
                        state: 'contact2',
                      })
                    }}
                  >
                    <b>enquire here</b>
                  </span>{' '}
                  {data.technicalAbilityParagraph2}
                </p>
              </div>
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td>
              <img
                src={CommunicationDesktop}
                className={styles.plannedCommImg}
                alt=''
              />
            </td>
            <td>
              <div className={styles.content}>
                <p className={styles.title}>24/7 COMMUNICATION</p>
                <p className={styles.paragraph}>
                  {parse(data.communicationFirstParagraph)}
                </p>
                <p className={styles.paragraph}>
                  {parse(data.communicationSecondParagraph)}
                </p>
              </div>
              <div></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
