import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import ContactIntegeration from '../Components/ContactUs/ContactIntegeration';
// import WhereToMap from '../Components/ContactUs/WhereToMap'

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  main: {
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
  },
  ContactIntegeration: {
    marginTop: isMobile ? '10vw' : '7vw',
    marginBottom: isMobile ? '10vw' : '7vw',
  },
  WhereToMap: {
    marginTop: isMobile ? '20vw' : '7vw',
    marginBottom: isMobile ? '10vw' : '7vw',
  },
});

export default function Home() {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const element = document.getElementById('contact');
    if (element) {
      if (history.location.state === 'contact')
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else if (history.location.state === 'contact2') element.scrollIntoView();
    }
  });
  return (
    <div>
      <div className={classes.ContactIntegeration} id='contact'>
        <ContactIntegeration />
      </div>
      <div className={classes.main}>
        {/* <div className={classes.WhereToMap}>
          <WhereToMap />
        </div> */}
      </div>
    </div>
  );
}
