import React from 'react';
import { useHistory } from 'react-router-dom';
import { Data } from '../../Data/Home/FindOut';
import { Container, Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import ImgMobile from '../../Images/findOutMoreMobile.png';
import ImgWeb from '../../Images/findOutMoreWeb.png';
import GetInTouchArrow from '../../Images/button.png';

const useStyles = makeStyles((theme) => ({
  general: {
    // height: isMobile ? '80vw' : '',
    paddingTop: isMobile ? '9.1vw' : '2vw',
    paddingBottom: isMobile ? '11vw' : '1.6vw',
    paddingLeft: isMobile ? '1.5vw' : '11.5vw',
    paddingRight: isMobile ? '1.5vw' : '30.4vw',
    backgroundImage: isMobile ? `url(${ImgMobile})` : `url(${ImgWeb})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  col: {
    verticalAlign: 'top',
    textAlign: 'Left',
  },
  title: {
    fontFamily: 'Gothic A1',
    color: '#ffffff',
    fontSize: isMobile ? 'max(7vw, 28px)' : 'max(3vw, 39px)',
    fontWeight: '900',
    paddingRight: isMobile ? '25vw' : '0px',
    marginBottom: isMobile ? '4vw' : '0px',
    width: isMobile ? '70vw' : '',
  },
  body: {
    width: isMobile ? '' : '35vw',
    fontFamily: 'Gothic A1',
    color: '#ffffff',
    fontSize: isMobile ? 'max(4vw, 18px)' : 'max(1.2vw, 16px)',
  },
  button: {
    textAlign: 'center',
    fontFamily: 'Gothic A1',
    marginLeft: !isMobile ? '7.5vw' : '0px',
    marginTop: isMobile ? '7vw' : '0px',
    width: isMobile ? 'max(30vw, 120px)' : '8vw',
    height: isMobile ? 'max(10vw, 40px)' : '2.7vw',
    backgroundColor: '#FA9E23',
    borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
    fontSize: isMobile ? 'max(3vw, 13px)' : '0.9vw',
    lineHeight: isMobile ? 'max(10vw, 40px)' : 'max(2.6vw, 40px)',
    cursor: 'pointer',
    color: '#ffffff',
  },
  arrow: {
    width: isMobile ? '10.144927536231884vw' : '3.541666666666667vw',
    marginLeft: isMobile ? '9.903381642512077vw' : '10vw',
    cursor: 'pointer',
  },
}));

export default function FindOutMore() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.general}>
      <Container>
        <Row>
          <Col xs={12} className={classes.col}>
            <div className={classes.title}>{Data.title} </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={9} className={classes.col}>
            <div className={classes.body}> {Data.body} </div>
          </Col>
          <Col
            xs={12}
            lg={3}
            // onClick={() => {
            //   history.push('/products');
            //   window.scrollTo(0, 0);
            // }}
          >
            <img
              src={GetInTouchArrow}
              className={classes.arrow}
              alt=''
              onClick={() => {
                history.push('/products');
                window.scrollTo(0, 0);
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
