import React from 'react';

import { isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap';
import data from '../../Data/Products/Travelators.json';
import parse from 'html-react-parser';

const useStyles = makeStyles({
  root: {
    paddingLeft: isMobile ? '4vw' : '12.5vw',
    paddingRight: isMobile ? '4vw' : '12.5vw',
    fontFamily: 'Gothic A1',
    textAlign: 'left',
  },
  title: {
    paddingBottom: isMobile ? '7.54vw' : '2.3vw',
    fontSize: isMobile ? '8vw' : '3vw',
    color: '#FA9E23',
    fontWeight: '900',
  },
  paragraphWithPadding: {
    width: isMobile ? '90vw' : '75vw',
    fontSize: isMobile ? '4.8vw' : '1.25vw',
    color: '#4B4B4B',
    paddingBottom: isMobile ? '5.85vw' : '1.5vw',
  },
  paragraphWithoutPadding: {
    width: isMobile ? '90vw' : '78vw',
    fontSize: isMobile ? '4.8vw' : '1.25vw',
    color: '#4B4B4B',
  },
  tableCol: {
    width: '45%',
    verticalAlign: 'top',
    paddingRight: isMobile ? '0.5vw' : '2.5vw',
  },
});

export default function Travelators() {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.title}>{data.title}</div>
      {isMobile ? (
        <div>
          <p>{parse(data.paragraph1)}</p>

          <p>{parse(data.paragraph2)}</p>
        </div>
      ) : (
        <table>
          <tbody>
            <tr>
              <td className={styles.tableCol}>
                <div>
                  <p>{parse(data.paragraph1)}</p>
                </div>
              </td>
              <td className={styles.tableCol}>
                <div>
                  <p>{parse(data.paragraph2)}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
}
