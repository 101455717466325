import React from 'react';
import WhoAreWe from '../Components/AboutUs/WhoAreWe';
import CheckOurServices from '../Components/AboutUs/CheckOurServices';
import QualityAssurance from '../Components/AboutUs/QualityAssurance';
import Clients from '../Components/AboutUs/Clients';
export default function AboutUs() {
  return (
    <>
      <WhoAreWe />
      <QualityAssurance />
      <Clients/>
      <CheckOurServices />
    </>
  );
}
