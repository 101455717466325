const Data = {
  page: 'GET TO KNOW MORE ABOUT BRILLIANT!',
  title: 'WHO WE ARE',
  paragraph1:
    'Brilliant Lifts Australia (BLA) is a full service elevator company who manufacture their own products in a state of the art factory.',
  paragraph2:
    'We don’t just pride ourselves on our reliability and durability, but on our integrity, too; our ability to deliver and to exceed customer expectations. We know that our customers are the most important part of our business and we consistently strive to improve further – never compromising for second best.',
  paragraph3:
    'Our offices and warehouses are based in Sydney and Canberra, but soon enough, there will be nationwide coverage in Australia.',
  paragraph4:
    'We have selected the smartest people in the industry to develop today’s team of talented technicians and managers. As our warehouses start to spread all around Australia, recruitment phases will take place where the most talented individuals will be given the chance to work at Brilliant. ',
  year: '2020',
  brochure:
    'Download our new brochure for 2020 celebrating 10 Brilliant years!',
};
module.exports = {
  Data,
};
