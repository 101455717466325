import React from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import WifiImage from '../../Images/wifiImage.png'

const useStyles = makeStyles({
  container: {
    backgroundColor: '#4B4B4B',
    height: isMobile ? '20vw' : '5.3vw',
    borderRadius: isMobile ? '4vw' : '1.3vw',
    marginTop: isMobile ? '10vw' : '3vw',
    textAlign: 'top',
  },
  wifiImage: {
    width: isMobile ? '25vw' : '7.7vw',
    position: 'relative',
    bottom: isMobile ? '2vw' : '1vw',
    left: isMobile ? '4vw' : '6vw',
  },
  selected: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: isMobile ? '4vw' : '1vw',
    color: '#FFFFFF',
    opacity: 1,
    margin: '0vw',
    marginLeft: isMobile ? '5vw' : '8vw',
    marginBottom: isMobile ? '4vw' : '2vw',
    cursor: 'pointer',
  },
  unSelected: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: isMobile ? '4vw' : '1vw',
    color: '#FFFFFF',
    opacity: 0.3,
    margin: '0vw',
    marginLeft: isMobile ? '5vw' : '8vw',
    marginBottom: isMobile ? '4vw' : '2vw',
    cursor: 'pointer',
  },
})

export default function SwitchProduct(props) {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <table>
        <tbody>
          <tr>
            <td>
              <img src={WifiImage} alt='wifi' className={styles.wifiImage} />
            </td>
            <td>
              <p
                className={
                  props.product === 'SingleLine'
                    ? styles.selected
                    : styles.unSelected
                }
                onClick={() => props.setProduct('SingleLine')}
              >
                {isMobile
                  ? 'EM-4GE2/1L'
                  : 'SINGLE LINE COMMUNICATION - EM-4GE2/1L'}
              </p>
            </td>
            <td>
              <p
                className={
                  props.product === 'FourLine'
                    ? styles.selected
                    : styles.unSelected
                }
                onClick={() => props.setProduct('FourLine')}
              >
                {isMobile
                  ? 'EM-4GE2/4L'
                  : 'FOUR LINE COMMUNICATION - EM-4GE2/4L'}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
