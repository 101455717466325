import React from 'react';
import data from '../../Data/Upgrade/DoNotSettle.json';

import { isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';

import dontSettle from '../../Images/dontsettle.png';
import dontSettleM from '../../Images/dontsettleM.png';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

const useStyles = makeStyles({
  title: {
    marginTop: isMobile ? '8vw' : '-0.7vw',
    marginBottom: isMobile ? '4vw' : '1vw',

    color: '#FA9E23',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: isMobile ? '6.757vw' : '3vw',
  },
  content: {
    color: '#4B4B4B',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '4.2vw' : '1.16vw',
    marginBottom: isMobile ? '3vw' : '1vw',
    width: isMobile ? '89vw' : '',
  },
  main: {
    marginLeft: isMobile ? '5vw' : '12.5',
    display: 'flex',
    flexDirection: isMobile ? 'column' : '',
    width: isMobile ? '90vw' : '75vw',
    height: isMobile ? '' : '31vw',
  },
  titleDiv: {
    width: isMobile ? '86.03vw' : '49vw',
    margin: '0 auto',
    textAlign: 'center',
  },
  contentDiv: {
    position: 'relative',
    width: isMobile ? '90vw' : '68.5vw',
    margin: '0 auto',
    textAlign: 'left',
    marginTop: isMobile ? '-1vw' : '',
    marginLeft: isMobile ? '0' : '1.5vw',
  },
  webImage: {
    width: isMobile ? '90vw' : '30vw',
    height: isMobile ? '' : '31.2vw',
    bottom: 0,
  },

  imageBtn: {
    bottom: 0,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '0 2.5vw' : '0px 0.5vw 0px 0.5vw',
    backgroundColor: '#FA9E23',
    color: '#fff',
    border: '1px solid #FA9E23',
    width: isMobile ? '28.15vw' : '8vw',
    height: isMobile ? '9.4vw' : '2.6vw',
    fontFamily: ' Gothic A1',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: isMobile ? '3.15vw' : '0.85vw',
    position: isMobile ? 'relative' : 'absolute',
  },
})
export default function DoNotSettle() {
  const styles = useStyles();
  const history = useHistory();

  const imgSrc = isMobile ? dontSettleM : dontSettle;
  return (
    <div className={styles.main}>
      <img src={imgSrc} className={styles.webImage} alt='' />

      <div className={styles.contentDiv}>
        {' '}
        <p className={styles.title}>{data.title}</p>
        <p className={styles.content}>{data.paragraph1}</p>
        <p className={styles.content}>{data.paragraph2}</p>
        <p className={styles.content}>{parse(data.paragraph3)}</p>
        {isMobile ? <br /> : ''}
        {/* {isMobile ? <br /> : ''} */}
        <button
          className={styles.imageBtn}
          onClick={() => {
            history.push({ pathname: '/contactus', state: 'contact2' })
          }}
        >
          ENQUIRE
        </button>
      </div>
    </div>
  );
}
