import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'
import SingleReportEvent from './SingleReportEvent'
import { SingleEvent } from '../../Data/LiftMonitoring/SingleEvent.json'
import { Data } from '../../Data/LiftMonitoring/ReportEvents.json'

import OrangeDotImage from '../../Images/Orange Dot.png'

const useStyles = makeStyles(() => ({
  container: {
    width: isMobile ? '93vw' : '75vw',
    margin: 'auto',
  },
  title: {
    fontWeight: '900',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontColor: '#FA9E23',
    fontSize: isMobile ? '7.2vw' : '3.7vw',
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  divider: {
    marginTop: isMobile ? '2vw' : '1.5vw',
    marginBottom: isMobile ? '2vw' : '1.5vw',
    borderTop: '2px solid #E5E5E5',
  },
  dot: {
    width: isMobile ? '3.8vw' : '1vw',
    margin: 'auto',
  },
  title: {
    fontWeight: '700',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.8vw' : '1.5vw',
    margin: isMobile ? '1vw 0vw' : '0vw',
  },
  body: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.2vw' : '1.2vw',
    margin: '0vw',
    // width: isMobile ? '44vw' : '20vw',
  },
}))

export default function ReportEvents() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <p style={{
        fontWeight: '900',
      fontStyle: 'normal',
      fontFamily: 'Gothic A1',
      color: '#FA9E23',
      fontSize: isMobile ? '7vw' : '3vw'
      }}>REPORT EVENTS</p>
      <div className={classes.eventsContainer}>
        {Data.map((elem) => (
          <SingleReportEvent title={elem.title} body={elem.body} />
        ))}
      </div>
      <div className={classes.divider}></div>
      <table>
        <tbody>
          <tr>
            <td>
              <img className={classes.dot} src={OrangeDotImage} />
            </td>
          </tr>
          <tr>
            <td>
              <p className={classes.title}>{SingleEvent.title}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p className={classes.body}>
                <span>
                  {SingleEvent.paragraph1}
                </span>
                <span style={{fontWeight: 900}}>
                  {SingleEvent.boldInP1}
                </span>
                <table style={{marginTop: '0.7vw', marginLeft: '3vw'}}>
                  {SingleEvent.bulletPoints.map((one)=>{
                    return (
                      <tr>
                        <td style={{width: isMobile? '3.8vw' :'2vw'}}>
                          <img className={classes.dot} src={OrangeDotImage} style={{width: isMobile ? '2.66vw' : '0.7vw',}}/>
                        </td>
                        <td>
                          {one}
                        </td>
                      </tr>
                    )
                  })}
                </table>
                <div style={{marginTop:'0.7vw'}}>
                  {SingleEvent.paragraph2}
                </div>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
