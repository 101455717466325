const Data = {
  title: 'GET TO KNOW BRILLIANT',
  bodyFirstParagraph:
    'Operating in NSW and the ACT, we are proud to be working for ',
  bodyClickablePart:
    'Australia’s top developers, builders and property owners. ',
  bodySecondParagraph:
    'We install our own lifts and escalators using highly skilled Australian technicians, and then care for your products with our own fully trained service mechanics. We take purposeful steps to ensure the best quality throughout our entire process.',
  bodyThirdParagraph:
    'Our Products are not just affordable, but they are of outstanding quality as well. We pride ourselves on our durability and reliability. Our lift and escalator systems were designed to suit all residential, commercial and high-rise projects. You can rely on Brilliant to provide you with 24/7 Support as well.',
  button: 'LEARN MORE',
};

module.exports = { Data };
