import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import stuckImg from '../../Images/stuckImg.png'
import { Data } from '../../Data/LiftMonitoring/BeingStuck.json'
import { useHistory } from 'react-router-dom'
import parse from 'html-react-parser'

export default function BeingStuck() {
  const carrouselStyles = {
    container: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      marginTop: isMobile ? '10vw' : '8.36vw',
      marginBottom: isMobile ? '10vw' : '7.975vw',
      //   height: isMobile && '31.87vw',
      width: isMobile && '79.2vw',
    },
    centerImgDiv: {
      backgroundSize: 'cover',
      margin: isMobile ? '7.5vw 0 0 0' : '1.5vw 0 0 0',
      minHeight: isMobile ? '110vw' : '74vw',
      width: '92vw',
      borderRadius: '20px',
      position: 'relative',
      // backgroundRepeat: 'no-repeat',
    },
    centerImg: {
      width: isMobile ? '' : '25.685vw',
      margin: '0 0 0 1vw',
      borderRadius: '20px',
    },
    textDiv: {
      width: isMobile ? '92vw' : '52.415vw',
      position: 'relative',
      backgroundColor: '#4B4B4B',
      borderRadius: isMobile ? '4.5vw' : '20px',
      paddingTop: isMobile ? '4vw' : '1.9vw',
      paddingLeft: isMobile ? '4vw' : '1.9vw',
      height: isMobile && '120vw',
    },
    content: {
      whiteSpace: 'pre-wrap',
      fontSize: isMobile ? '4.05vw' : '1.15vw',
      width: isMobile ? '85vw' : '38vw',
      fontFamily: 'Gothic A1',
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#fff',
      margin: '0',
    },
    mainTitle: {
      fontStyle: 'normal',
      width: isMobile ? '' : '32.395vw',
      fontSize: isMobile ? '' : '3.19vw',
      color: '#fff',
      textTransform: 'uppercase',
      margin: '1vw 0 2.5vw 0',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
    },
    btn: {
      background: ' #FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
      width: isMobile ? '28.15vw' : '8vw',
      height: isMobile ? '9.4vw' : '2.6vw',
      marginTop: isMobile ? '1.5vw' : '2.5vw',
      border: 'none',
      outline: 'none',
      fontSize: isMobile ? '3.15vw' : '0.85vw',
      fontWeight: '500',
      cursor: 'pointer',
    },
    imgText: {
      position: 'absolute',
      left: '4vw',
      top: '58vw',
      fontFamily: 'Gothic A1',
      fontSize: '5.8vw',
      fontStyle: 'normal',
      fontWeight: '900',
      color: '#fff',
    },
  }
  const useStyles = makeStyles(carrouselStyles)
  const styles = useStyles()
  const history = useHistory()

  return (
    <div className={styles.container}>
      <div className={styles.textDiv}>
        <div>
          <div
          // style={{ position: 'absolute', left: '5%' }}
          // className={index == 0 ? styles.SecondFadeOut : styles.SecondFadeIn}
          >
            <h2 className={styles.mainTitle}>{Data.title}</h2>
            <pre className={styles.content}>{parse(Data.content)}</pre>
            <button
              className={styles.btn}
              onClick={() => {
                history.push({
                  pathname: '/services',
                  state: 'communication',
                })
              }}
            >
              LEARN MORE
            </button>
          </div>
        </div>
      </div>

      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),
      url(${stuckImg})`,
          }}
        ></div>
      ) : (
        <img src={stuckImg} alt='' className={styles.centerImg} />
      )}
    </div>
  )
}
