import React from 'react'
import { Data } from '../../Data/AboutUs/WhoAreWe'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import buildings from '../../Images/buildings_with_logo.png'
import buildingsMob from '../../Images/buildingsMob_with_logo.png'
import whiteDots from '../../Images/whiteDots.png'
import OrangeCircle from '../../Images/OrangeCircle.png'
import parse from 'html-react-parser'
// import australianLogo from '../../Images/australian_logo.png'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginLeft: isMobile ? '2.5vw' : '12.5vw',
    marginBottom: isMobile ? '14vw' : '8vw',
    marginTop: isMobile ? '8vw' : '4.32vw',
  },
  buildings: {
    width: isMobile ? '95vw' : '75vw',
    height: isMobile ? '60vw' : '',
  },
  table: {
    width: isMobile ? '95vw' : '75vw',
    backgroundColor: '#FA9E23',
    marginTop: isMobile ? '-10vw' : '-3.5vw',
    paddingBottom: isMobile ? '10vw' : '',
    borderRadius: isMobile ? '4.82vw' : '1.14vw',
  },
  whiteDots: {
    width: isMobile ? '20vw' : '7.71vw',
    height: isMobile ? '20vw' : '7.71vw',
    marginTop: isMobile ? '-10vw' : '-3.855vw',
  },
  col2: { display: 'flex', justifyContent: 'center' },
  innerCol1: {
    verticalAlign: 'top',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: isMobile ? '7.25vw' : '2.91vw',
    lineHeight: isMobile ? '8.94vw' : '3.85vw',
    paddingLeft: isMobile ? '3.62vw' : '1.875vw',
    paddingTop: isMobile ? '17.9vw' : '2.66vw',
    paddingBottom: isMobile ? '0.73vw' : '2.34vw',
    /* identical to box height */

    color: '#FFFFFF',
  },
  textCols: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '4.35vw' : '1.14vw',
    lineHeight: isMobile ? '5.31vw' : '1.66vw',
    color: '#FFFFFF',
    verticalAlign: 'top',
    paddingLeft: isMobile ? '3.62vw' : '1.875vw',
    paddingRight: isMobile ? '1.45vw' : '1.9vw',
    paddingBottom: isMobile ? '5vw' : '',
  },
  smallText: {
    color: 'white',
    textDecoration: 'underline',
    fontSize: isMobile ? '4vw' : '0.9vw',
    lineHeight: isMobile ? '' : '1.14vw',
    padding: isMobile ? '2.5vw' : '',
  },
  orangeCircle: {
    width: isMobile ? '0vw' : '2.3vw',
    marginRight: '1vw',
  },
  Text: {
    color: '#FA9E23',
    fontSize: isMobile ? '6.5vw' : '3vw',
    fontWeight: '900',
    textAlign: 'center;',
    fontFamily: 'Gothic A1',
    paddingRight: '1vw',
  },
  pageTitle: {
    marginLeft: isMobile ? '0vw' : '12.5vw',
    marginTop: isMobile ? '7.9vw' : '5vw',
  },
})

export default function WhoAreWe() {
  const classes = useStyles()

  return (
    <div>
      <table className={classes.pageTitle}>
        <tbody>
          <tr>
            <td>
              <img src={OrangeCircle} className={classes.orangeCircle} alt='' />
            </td>
            <td>
              <img src={OrangeCircle} className={classes.orangeCircle} alt='' />
            </td>
            <td className={classes.Text}> {Data.page}</td>
            <td>
              <img src={OrangeCircle} className={classes.orangeCircle} alt='' />
            </td>
            <td>
              <img src={OrangeCircle} className={classes.orangeCircle} alt='' />
            </td>
          </tr>
        </tbody>
      </table>

      <table className={classes.root}>
        <tbody>
          <tr>
            <td>
              {' '}
              <img
                src={isMobile ? buildingsMob : buildings}
                className={classes.buildings}
                alt=''
              />
            </td>
          </tr>
          <tr>
            <td className={classes.col2}>
              {' '}
              <img src={whiteDots} className={classes.whiteDots} alt='' />
            </td>
          </tr>
          <tr>
            <td>
              {isMobile ? (
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td className={classes.innerCol1}>{Data.title}</td>
                    </tr>
                    <tr>
                      <td className={classes.textCols}>{Data.paragraph1}</td>
                    </tr>
                    <tr>
                      <td className={classes.textCols}>{Data.paragraph2}</td>
                    </tr>
                    <tr>
                      <td className={classes.textCols}>{Data.paragraph3}</td>
                    </tr>
                    <tr>
                      <td className={classes.textCols}>
                        {Data.paragraph4}
                        <br />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <div
                        style={{ marginTop: '0.9vw' }}
                        className={classes.smallText}
                      >
                        {' '}
                        {/* <span>{Data.year}</span>
                          &nbsp; */}
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            window.open(
                              'https://drive.google.com/uc?export=download&id=1ab0QACx5MVWqO3CVDrqWfzpJPLdIj3P2'
                            )
                          }
                        >
                          {Data.brochure}
                        </span>
                      </div>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td className={classes.innerCol1}>{Data.title}</td>
                    </tr>
                    <tr>
                      <td
                        className={classes.textCols}
                        style={{ paddingRight: '10vw', paddingBottom: '2vw' }}
                      >
                        {parse(Data.paragraph1)}
                      </td>
                      <td
                        className={classes.textCols}
                        style={{ paddingBottom: '2vw' }}
                      >
                        {parse(Data.paragraph3)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className={classes.textCols}
                        style={{ paddingRight: '10vw', paddingBottom: '2vw' }}
                      >
                        {parse(Data.paragraph2)}
                      </td>
                      <td
                        className={classes.textCols}
                        style={{ paddingBottom: '2vw' }}
                      >
                        <div style={{ marginTop: '-1vw' }}>
                          {' '}
                          {parse(Data.paragraph4)}
                        </div>
                        <div
                          style={{ marginTop: '0.9vw' }}
                          className={classes.smallText}
                        >
                          {' '}
                          {/* <span>{Data.year}</span>
                          &nbsp; */}
                          <span
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              window.open(
                                'https://drive.google.com/uc?export=download&id=1ab0QACx5MVWqO3CVDrqWfzpJPLdIj3P2'
                              )
                            }
                          >
                            {Data.brochure}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
