import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
// import esSeriesImg from '../../Images/escalators-esSeries.png'
// import esSeriesImgMobile from '../../Images/escalators-esSeriesMobile.png'
import BLAImg from '../../Images/BLAImg.png';
import BLAImgMobile from '../../Images/BLAImgMobile.png';
// import PassengerImg from '../../Images/commercialElevators-Passenger.png'
// import PassengerImgMobile from '../../Images/commercialElevators-PassengerMobile.png'
import centerImg from '../../Images/centerImageEscalators.png';

import centerImgMobile from '../../Images/commercialElevators-centerImgMobile.png';
import data from '../../Data/Products/EscalatorsCarrousel.json';
import { carrouselStyles } from './CarrouselStyles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(carrouselStyles);

export default function Carrousel() {
  const styles = useStyles();
  const [selected, setSelected] = useState('panoramic');
  const history = useHistory();
  const selectedData =
    selected === 'panoramic'
      ? data.Panoramic
      : selected === 'passenger'
      ? data.Panoramic2
      : data.Panoramic3;
  return (
    <div style={{ margin: '4vw' }} className={styles.container}>
      <div className={styles.carrousel}>
        {/* <div className={styles.optionDiv}>
          {selected === 'esSeries' ? (
            <img
              alt=""
              src={isMobile ? esSeriesImgMobile : esSeriesImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('esSeries')}
            >
              es Series
            </p>
          )}
        </div> */}
        <div className={styles.optionDiv}>
          {selected === 'panoramic' ? (
            <img
              alt=''
              src={isMobile ? BLAImgMobile : BLAImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('panoramic')}
            >
              BLA Escalators
            </p>
          )}
        </div>
        {/* <div className={styles.optionDiv}>
          {selected === 'passenger' ? (
            <img
              alt=""
              src={isMobile ? PassengerImgMobile : PassengerImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('passenger')}
            >
              passenger
            </p>
          )}
        </div> */}
      </div>
      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),
      url(${centerImg})`,
          }}
        >
          <span className={styles.imgText}>Commercial Elevators</span>
        </div>
      ) : (
        <img
          src={isMobile ? centerImgMobile : centerImg}
          alt=''
          className={styles.centerImg}
        />
      )}
      <div className={styles.textDiv}>
        {isMobile ? null : <h2 className={styles.mainTitle}>ESCALATORS</h2>}
        <h4 className={styles.subTitle}>{selectedData.title}</h4>
        <pre className={styles.content}>{selectedData.content}</pre>
        <button
          className={styles.btn}
          onClick={() => {
            history.push({ pathname: '/contactus', state: 'contact2' });
          }}
        >
          ENQUIRE
        </button>
      </div>
    </div>
  );
}
