import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'
import { Data } from '../../Data/LiftMonitoring/Communication.json'
import { useLocation, useHistory } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
  container: {
    width: isMobile ? '92vw' : '24vw',
  },
  title: {
    fontWeight: '900',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '7.3vw' : '3vw',
    color: '#FA9E23',
    margin: isMobile ? '10vw 0vw 0vw 0vw' : '0vw',
    width: isMobile ? '60vw' : '20vw',
  },
  content: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.2vw' : '1.2vw',
    margin: '0vw',
    width: isMobile ? '92vw' : '20vw',
    marginTop: isMobile ? '5vw' : '2.6vw',
  },
  btn: {
    background: '#FA9E23',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
    width: isMobile ? '30vw' : '8vw',
    height: isMobile ? '9.4vw' : '2.6vw',
    marginTop: isMobile ? '10vw' : '2vw',
    marginBottom: isMobile ? '10vw' : '2vw',
    border: 'none',
    outline: 'none',
    fontSize: isMobile ? '3.15vw' : '0.85vw',
    fontWeight: '500',
    cursor: 'pointer',
    color: '#fff',
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4vw' : '1vw',
  },
}))

export default function Communication(props) {
  const classes = useStyles()
  const history = useHistory()
  const textData =
    props.product === 'SingleLine' ? Data['SingleLine'] : Data['FourLine']
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{textData.title}</h1>
      <p className={classes.content}>{textData.content1}</p>
      <p className={classes.content}>{textData.content2}</p>
      <button
        className={classes.btn}
        onClick={() => {
          history.push('/contactus')
        }}
      >
        Learn More
      </button>
    </div>
  )
}
