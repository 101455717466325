import React from 'react'

import { isMobile } from 'react-device-detect'

import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router'

import data from '../../Data/Services/Trustworthy.json'
import ReadMoreWebsite from '../../Images/readMoreWebsite.png'
import ReadMoreMobile from '../../Images/readMoreMobile.png'

const useStyles = makeStyles({
  title: {
    color: '#FA9E23',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: isMobile ? '6.757vw' : '3vw',
  },
  content: {
    color: '#4B4B4B',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '5vw' : '1.667vw',
    paddingRight: isMobile ? '' : '3vw',
    paddingLeft: isMobile ? '' : '3vw',
  },
  center: {
    width: '100vw',
  },
  titleDiv: {
    width: isMobile ? '92vw' : '75vw',
    // margin: '0 auto',
    textAlign: 'center',
  },
  contentDiv: {
    width: isMobile ? '92vw' : '75vw',
    // margin: '0 auto',
    textAlign: 'center',
    marginTop: isMobile ? '-1vw' : '',
  },
  webImage: {
    width: '100%',
  },
  imageDiv: {
    marginTop: isMobile ? '14vw' : '6vw',
    // margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    width: isMobile ? '92vw' : '75vw',
  },
  imageText: {
    position: 'absolute',
    top: isMobile ? '8vw' : '2.7vw',
    left: '4vw',
    fontWeight: '900',
    fontSize: isMobile ? '6.8vw' : '3.02vw',
    textAlign: 'left',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    color: '#fff',
  },
  imageTextDiv: {
    width: isMobile ? '78.83vw' : '49.63vw',
  },
  imageBtn: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '0 2.5vw' : '0px 0.5vw 0px 0.5vw',
    backgroundColor: '#FA9E23',
    color: '#fff',
    border: '1px solid #FA9E23',
    width: isMobile ? '28.15vw' : '8vw',
    height: isMobile ? '9.4vw' : '2.65vw',
    fontFamily: ' Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '3.15vw' : '0.85vw',
    position: 'absolute',
    top: isMobile ? '45vw' : '13.5vw',
    left: '4vw',
    cursor: 'pointer',
  },
})

export default function Trustworthy() {
  const styles = useStyles()
  const history = useHistory()
  const redirect = () => {
    // document
    // .getElementById("quality")
    // .scrollIntoView({ behavior: "smooth", block: "center" });

    history.push('/aboutus')
    var body = document.body,
      html = document.documentElement

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    
      isMobile
        ? window.scroll(0, height / 2.25)
        : window.scroll(0, height / 1.75);
    
  }
  const imgSrc = isMobile ? ReadMoreMobile : ReadMoreWebsite
  const imgText = isMobile ? data.imageTextMobile : data.imageTextWebsite
  return (
    <div className={styles.center}>
      <div className={styles.titleDiv}>
        <p className={styles.title}>{data.title}</p>
      </div>
      <div className={styles.contentDiv}>
        <p className={styles.content}>{data.content}</p>
      </div>
      <div className={styles.imageDiv}>
        <img src={imgSrc} className={styles.webImage} alt="" />
        <div className={styles.imageTextDiv}>
          <pre className={styles.imageText}>{imgText}</pre>
        </div>
        <button className={styles.imageBtn} onClick={() => redirect()}>
          QUALITY
        </button>
      </div>
    </div>
  )
}

// const useStyles = makeStyles({
//   root: {
//     paddingLeft: isMobile ? '3vw' : '12.5vw',
//     paddingRight: isMobile ? '3vw' : '12.5vw',
//     fontFamily: 'Gothic A1',
//     textAlign: 'left',
//   },
//   title: {
//     paddingBottom: isMobile ? '7.54vw' : '2.3vw',
//     fontSize: isMobile ? '8vw' : '3vw',
//     color: '#FA9E23',
//     fontWeight: 'bold',
//   },
//   paragraph: {
//     width: isMobile ? '99.5vw' : '75vw',
//     fontSize: isMobile ? '4.8vw' : '1.25vw',
//     color: '#4B4B4B',
//   },
// })

// export default function Escalators() {
//   const styles = useStyles()
//   return (
//     <div className={styles.root}>
//       <div className={styles.title}>ESCALATORS</div>
//       <div className={styles.paragraph}>
//         The Brilliant ESP range has the leading edge in performance, longevity
//         and reliability. The Brilliant ESP range delivers unsurpassed quality in
//         the escalator market. Our heavy duty highly specified units can cope
//         with the rigors and demands of all applications, including shopping
//         centres, airports and railways.
//       </div>
//     </div>
//   )
// }
