import React from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  stayAheadCardDiv: {
    width: isMobile ? "42vw" : "14.167vw",
    height: isMobile ? "42vw" : "14vw",
    backgroundColor: "#4B4B4B",
    borderRadius: isMobile ? "4.5vw" : "1.04vw",
    position: "relative",
    marginRight: "1vw",
  },
  textDiv: {
    width: isMobile ? "35.6vw" : "11vw",
    margin: "0 auto",
    textAlign: "center",
    position: "relative",
    top: isMobile ? "7.2vw" : "3vw",
  },
  text: {
    whiteSpace: "pre-wrap",
    color: "#fff",
    fontFamily: "Gothic A1",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: isMobile ? "4.5vw" : "1.3vw",
  },
  cardImg: {
    width: isMobile ? "50%" : "7.5vw",
    position: "absolute",
    top: "73.2%",
    left: isMobile ? "25%" : "23.5%",
  },
});

export default function StayAheadCard({ text, image }) {
  const styles = useStyles()
  return (
    <div className={styles.stayAheadCardDiv}>
      <div className={styles.textDiv}>
        <p className={styles.text}>{text}</p>
      </div>
      <img className={styles.cardImg} src={image} alt="" />
    </div>
  );
}
