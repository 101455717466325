import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import maintenanceImg from '../../Images/maintenanceImg.png';
import customInstallations from '../../Images/customInstallations.png';
import stuckImg from '../../Images/stuckImg.png';
import maintenanceArrowUp from '../../Images/maintenanceArrowUp.png';
import maintenanceArrowDown from '../../Images/maintenanceArrowDown.png';
import blackScroll from '../../Images/blackScroll.png';
import scrollbar from '../../Images/scrollbar.png';
import data from '../../Data/Home/MaintenanceCarousel.json';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

export default function Carrousel() {
  const carrouselStyles = {
    container: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      marginTop: isMobile ? '10vw' : '8.36vw',
      marginBottom: isMobile ? '10vw' : '7.975vw',
      //   height: isMobile && '31.87vw',
      width: isMobile && '79.2vw',
    },
    centerImgDiv: {
      backgroundSize: 'cover',
      margin: isMobile ? '7.5vw 0 0 0' : '1.5vw 0 0 0',
      minHeight: isMobile ? '110vw' : '74vw',
      width: '92vw',
      borderRadius: '20px',
      position: 'relative',
      // backgroundRepeat: 'no-repeat',
    },
    centerImg: {
      width: isMobile ? '' : '25.685vw',
      margin: '0 0 0 1vw',
      borderRadius: '20px',
    },
    textDiv: {
      width: isMobile ? '92vw' : '52.415vw',
      position: 'relative',
      backgroundColor: '#4B4B4B',
      borderRadius: isMobile ? '4.5vw' : '20px',
      paddingTop: isMobile ? '4vw' : '1.9vw',
      paddingLeft: isMobile ? '4vw' : '1.9vw',
      height: isMobile && '120vw',
    },
    content: {
      whiteSpace: 'pre-wrap',
      fontSize: isMobile ? '4.05vw' : '1.15vw',
      width: isMobile ? '85vw' : '38vw',
      fontFamily: 'Gothic A1',
      fontWeight: '400',
      fontStyle: 'normal',
      color: '#fff',
      margin: '0',
    },
    mainTitle: {
      fontStyle: 'normal',
      width: isMobile ? '' : '32.395vw',
      fontSize: isMobile ? '' : '3.19vw',
      color: '#fff',
      textTransform: 'uppercase',
      margin: '1vw 0 2.5vw 0',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
    },
    btn: {
      background: ' #FFFFFF',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
      width: isMobile ? '28.15vw' : '8vw',
      height: isMobile ? '9.4vw' : '2.6vw',
      marginTop: isMobile ? '1.5vw' : '2.5vw',
      border: 'none',
      outline: 'none',
      fontSize: isMobile ? '3.15vw' : '0.85vw',
      fontWeight: '500',
      cursor: 'pointer',
    },
    imgText: {
      position: 'absolute',
      left: '4vw',
      top: '58vw',
      fontFamily: 'Gothic A1',
      fontSize: '5.8vw',
      fontStyle: 'normal',
      fontWeight: '900',
      color: '#fff',
    },
    ArrowUp: {
      position: 'absolute',
      left: '58%',
      marginTop: '1.5%',
      cursor: 'pointer',
    },
    ArrowDown: {
      position: 'absolute',
      left: '58%',
      marginTop: '27%',
      cursor: 'pointer',
      zIndex: 10,
    },
    ScrollBar: {
      marginLeft: '0.33vw',
      marginTop: '7.5vw',
      cursor: 'pointer',
    },
    ScrollUp: {
      position: 'absolute',
      marginLeft: '0.33vw',
      marginTop: '7.5%',
      transition: 'margin-top 0.5s',
    },
    ScrollMid: {
      position: 'absolute',
      marginLeft: '0.33vw',
      marginTop: '12.1%',
      transition: 'margin-top 0.5s',
    },
    ScrollDown: {
      position: 'absolute',
      marginLeft: '0.33vw',
      marginTop: '17.1%',
      transition: 'margin-top 0.5s',
    },
    FirstFadeIn: {
      position: 'absolute',
      width: '100%',
      bottom: '15%',
      opacity: 1,
      transition: 'opacity 2s, bottom 1s',
    },
    FirstFadeOut: {
      position: 'absolute',
      width: '100%',
      bottom: '100%',
      opacity: 0,
      transition: 'bottom 1s, opacity 0.5s',
    },
    SecondFadeIn: {
      position: 'absolute',
      width: '100%',
      top: isMobile ? '10%' : '5%',
      opacity: 1,
      transition: 'opacity 2s, top 1s',
    },
    SecondFadeOutUp: {
      position: 'absolute',
      width: '100%',
      top: '-100%',
      opacity: 0,
      transition: 'top 1s, opacity 0.5s',
    },
    SecondFadeOutDown: {
      position: 'absolute',
      width: '100%',
      top: '100%',
      opacity: 0,
      transition: 'top 1s, opacity 0.5s',
    },
    ThirdFadeIn: {
      position: 'absolute',
      width: '100%',
      top: '5%',
      opacity: 1,
      transition: 'opacity 2s, top 1s',
    },
    ThirdFadeOut: {
      position: 'absolute',
      width: '100%',
      top: '100%',
      opacity: 0,
      transition: 'top 1s, opacity 0.5s',
    },
    arrowsMobile: {
      position: 'relative',
      bottom: '10vw',
      zIndex: 10,
    },
    ArrowUpMobile: {
      position: 'absolute',
      right: '-10vw',
      top: '5vw',
      cursor: 'pointer',
      transform: 'rotate(90deg)',
    },
    ArrowDownMobile: {
      position: 'absolute',
      left: '0vw',
      top: '5vw',
      cursor: 'pointer',
      zIndex: 10,
      transform: 'rotate(90deg)',
    },
    ScrollBarMobile: {
      position: 'absolute',
      left: '55%',
      marginTop: '-15vw',
      cursor: 'pointer',
      transform: 'rotate(90deg)',
    },
    ScrollUpMobile: {
      position: 'absolute',
      left: '75%',
      marginTop: '-0.7vw',
      transition: 'left 0.5s',
      transform: 'rotate(90deg)',
    },
    ScrollMidMobile: {
      position: 'absolute',
      left: '56%',
      marginTop: '-0.7vw',
      transition: 'left 0.5s',
      transform: 'rotate(90deg)',
    },
    ScrollDownMobile: {
      position: 'absolute',
      left: '37%',
      marginTop: '-0.7vw',
      transition: 'left 0.5s',
      transform: 'rotate(90deg)',
    },
  };
  const useStyles = makeStyles(carrouselStyles);
  const styles = useStyles();
  const history = useHistory();
  const Data = data.data;
  const [index, setIndex] = useState(0);

  const delay = 7000;
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === Data.length - 1 ? 0 : prevIndex + 1
      );
    }, delay);
    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className={styles.container}>
      <div className={styles.textDiv}>
        <div>
          <div
            // style={{ position: 'absolute', left: '5%' }}
            className={index == 0 ? styles.FirstFadeIn : styles.FirstFadeOut}
          >
            <h2 className={styles.mainTitle}>{Data[0].title}</h2>
            <pre className={styles.content}>{parse(Data[0].content)}</pre>
            <button
              className={styles.btn}
              onClick={() => {
                history.push({
                  pathname: '/services',
                  state: 'planned-maintenance',
                });
              }}
            >
              LEARN MORE
            </button>
          </div>
          <div
            // style={{ position: 'absolute', left: '5%' }}
            className={index == 1 ? styles.SecondFadeIn : index == 2? styles.SecondFadeOutUp : styles.SecondFadeOutDown}
          >
            <h2 className={styles.mainTitle}>{Data[1].title}</h2>
            <pre className={styles.content}>{parse(Data[1].content)}</pre>
            <button
              className={styles.btn}
              onClick={() => {
                history.push({
                  pathname: '/liftmonitoring',
                });
              }}
            >
              LEARN MORE
            </button>
          </div>
          <div
            // style={{ position: 'absolute', left: '5%' }}
            className={index == 2 ? styles.ThirdFadeIn : styles.ThirdFadeOut}
          >
            <h2 className={styles.mainTitle}>{Data[2].title}</h2>
            <pre className={styles.content}>{parse(Data[2].content)}</pre>
            <button
              className={styles.btn}
              onClick={() => {
                history.push({
                  pathname: '/contactus',
                });
              }}
            >
              GET A QUOTE
            </button>
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className={styles.arrowsMobile}>
          <img
            src={maintenanceArrowUp}
            className={styles.ArrowUpMobile}
            style={{ width: isMobile ? '15vw' : '5.61vw' }}
            onClick={() => {
              setIndex((prevIndex) => 
                prevIndex === 0 ? 0 : prevIndex - 1
              );
              resetTimeout();
            }}
          ></img>
          <img
            src={maintenanceArrowDown}
            className={styles.ArrowDownMobile}
            style={{ width: isMobile ? '15vw' : '5.61vw' }}
            onClick={() => {
              setIndex((prevIndex) =>
                prevIndex === Data.length - 1 ? Data.length-1 : prevIndex + 1
              );
              resetTimeout();
            }}
          ></img>
          <div
            className={
              index == 0 ? styles.ScrollUpMobile :  index == 2? styles.ScrollDownMobile : styles.ScrollMidMobile
            }
          >
            <img
              src={blackScroll}
              style={{ width: isMobile ? '1.65vw' : '0.55vw' }}
            ></img>
          </div>
          <img
            src={scrollbar}
            className={styles.ScrollBarMobile}
            style={{ width: isMobile ? '1.65vw' : '0.55vw' }}
          ></img>
        </div>
      ) : (
        <div className={styles.arrows}>
          <img
            src={maintenanceArrowUp}
            className={styles.ArrowUp}
            style={{ width: isMobile ? '15vw' : '5.61vw' }}
            onClick={() => {
              setIndex((prevIndex) => 
                prevIndex === 0 ? 0 : prevIndex - 1
              );
              resetTimeout();
            }}
          ></img>
          <img
            src={maintenanceArrowDown}
            className={styles.ArrowDown}
            style={{ width: isMobile ? '15vw' : '5.61vw' }}
            onClick={() => {
              setIndex((prevIndex) =>
                prevIndex === Data.length - 1 ? Data.length-1 : prevIndex + 1
              );
              resetTimeout();
            }}
          ></img>
          <div className={index == 0 ? styles.ScrollUp : index == 2? styles.ScrollDown : styles.ScrollMid}>
            <img src={blackScroll} style={{ width: '0.55vw' }}></img>
          </div>
          <div style={{width: '0.2vw'}}>
            <img
              src={scrollbar}
              className={styles.ScrollBar}
              style={{ width: '0.55vw' }}
            ></img>
          </div>
        </div>
      )}

      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),
      url(${index === 0 ? maintenanceImg : index == 1? stuckImg : customInstallations})`,
          }}
        ></div>
      ) : (
        <img
          src={index === 0 ? maintenanceImg : index == 1? stuckImg : customInstallations}
          alt=''
          className={styles.centerImg}
        />
      )}
    </div>
  );
}
