import React from 'react'
import AskAbout from '../Components/Upgrade/AskAbout'
import DoNotSettle from '../Components/Upgrade/DoNotSettle'
import StayAhead from '../Components/Upgrade/StayAhead'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  StayAhead: {
    marginTop: isMobile ? '20vw' : '6.5vw',
    minHeight: isMobile ? 'unset' : '38vw',
  },
  DoNotSettle: {
    marginTop: isMobile ? '25vw' : '9vw',
    marginBottom: isMobile ? '20vw' : '7.8vw',
    marginLeft: isMobile ? '0' : '12.5vw',
  },
  AskAbout: {},
})

export default function Upgrade() {
  const classes = useStyles()
  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className={classes.StayAhead}>
        <StayAhead />
      </div>
      <div className={classes.DoNotSettle}>
        <DoNotSettle />
      </div>
      <div className={classes.AskAbout}>
        <AskAbout />
      </div>
    </div>
  )
}
