import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'
import { Row, Col} from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: isMobile ? '45vw' : '15vw',
    height: isMobile ? '25vw' : '7vw',
    verticalAlign: 'top',
  },
  containerSmall: {
    maxWidth: isMobile ? '45vw' : '15vw',
    height: isMobile ? '18.5vw' : '4vw',
    verticalAlign: 'top',
  },
  dot: {
    width: isMobile ? '3.8vw' : '1vw',
    margin: 'auto',
  },
  number: {
    fontWeight: '900',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.8vw' : '1.67vw',
    margin: '0vw',
    marginRight: isMobile ? '2vw' : '1vw',
    color: '#FA9E23',
  },
  text: {
    fontWeight: '400',
    fontStyle: 'normal',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '3.8vw' : '1vw',
    margin: '0vw',
    width: isMobile ? '35vw' : '11.5vw',
  },
}))

export default function SingleFeature(props) {
  const classes = useStyles()

  return (
    <div
      className={
        props.text.length > 50 ? classes.container : classes.containerSmall
      }
    >
      <Row>
        <Col xs={2} style={{alignItems: "right"}}>
              <p className={classes.number}>{props.number}</p>
        </Col>
        <Col xs={3}>
              <p className={classes.text}>{props.text}</p>
        </Col>
      </Row>
    </div>
  )
}
