import React from 'react'

import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import data from '../../Data/Upgrade/StayAhead.json'
import StayAheadCard from './StayAheadCard'
import StayAheadCardBig from './StayAheadCardBig'
import ReplacingCard from '../../Images/stayAheadCard1.png'
import VisualCard from '../../Images/stayAheadCard2.png'
import ReducingCard from '../../Images/stayAheadCard3.png'
import ModernizingCard from '../../Images/stayAheadCard4.png'
import RefiningCard from '../../Images/stayAheadCard5.png'

const useSyles = makeStyles({
  stayAheadDiv: {
    margin: isMobile ? "0 auto" : "0 auto",
    width: isMobile ? "90vw" : "75vw",
  },
  title: {
    fontFamily: "Gothic A1",
    fontSize: isMobile ? "6.75vw" : "3vw",
    fontWeight: 900,
    color: "#FA9E23",
  },
  titleDiv: {
    width: isMobile ? "80vw" : "60vw",
    textAlign: "left",
  },
  contentDiv: {
    maxWidth: isMobile ? "92vw" : "70vw",
    textAlign: "left",
    marginBottom: isMobile ? "" : "4vw",
  },
  content: {
    fontFamily: "Gothic A1",
    fontSize: isMobile ? "4vw" : "1.667vw",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#4B4B4B",
    whiteSpace: "pre-wrap",
  },
  cardsDiv: {
    // width: isMobile ? '90vw' : '75vw',
  },
  cardsTable: {
    marginTop: isMobile         ? "-10vw"         : "",
    borderCollapse: "separate",
    borderSpacing: "0 20vw",
    // width: '90vw',
  },
  rightCol: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});

const cards = [
  {
    text: data.ReplacingCard,
    image: ReplacingCard,
  },

  {
    text: data.VisualCard,
    image: VisualCard,
  },
  {
    text: data.ReducingCard,
    image: ReducingCard,
  },
  {
    text: data.ModernizingCard,
    image: ModernizingCard,
  },
  {
    text: data.RefiningCard,
    image: RefiningCard,
  },
]

export default function StayAhead() {
  const styles = useSyles()
  const title = isMobile ? data.titleMobile : data.title
  const content = isMobile ? data.contentMobile : data.content

  return (
    <div className={styles.stayAheadDiv}>
      <div className={styles.titleDiv}>
        <pre className={styles.title}>{title}</pre>
      </div>
      <div className={styles.contentDiv}>
        <pre className={styles.content}>{content}</pre>
      </div>
      <div className={styles.cardsDiv}>
        {isMobile ? (
          <table className={styles.cardsTable}>
            <tbody>
              <tr className={styles.cardsRow}>
                <td className={styles.leftCol}>
                  <StayAheadCard
                    text={data.ReplacingCard}
                    image={ReplacingCard}
                  />
                </td>
                <td className={styles.rightCol}>
                  <StayAheadCard text={data.VisualCard} image={VisualCard} />
                </td>
              </tr>
              <tr>
                <td className={styles.leftCol}>
                  <StayAheadCard
                    text={data.ReducingCard}
                    image={ReducingCard}
                  />
                </td>
                <td className={styles.rightCol}>
                  <StayAheadCard
                    text={data.ModernizingCard}
                    image={ModernizingCard}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan='2'>
                  <StayAheadCardBig
                    text={data.RefiningCard}
                    image={RefiningCard}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <table>
            <tbody>
              <tr>
                {cards.map((card) => (
                  <td>
                    <StayAheadCard text={card.text} image={card.image} />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
