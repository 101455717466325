import { isMobile } from 'react-device-detect';

const carrouselStyles = {
  container: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    height: isMobile && '31.87vw',
    width: isMobile && '31.87vw',
  },
  carrousel: {
    width: isMobile ? '92vw' : '5.31vw',
    minHeight: isMobile ? '23vw' : '32.5vw',
    backgroundColor: '#fff',
    border: '8px solid #FA9E23',
    boxSizing: 'border-box',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: isMobile ? 'row' : 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  carrouselText: {
    writingMode: isMobile ? 'horizontal-tb' : 'vertical-rl',
    transform: isMobile ? 'rotate(0deg)' : 'rotate(180deg)',
    fontWeight: '500',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4.05vw' : '1.25vw',
    color: 'rgba(75, 75, 75, 0.3)',
    cursor: 'pointer',
    textTransform: 'uppercase',
    margin: '1vw',
  },
  circleCarrousel: {
    width: isMobile ? '21.8vw' : '7.7vw',
    margin: isMobile ? '20vw 0 0 0' : '0 0 0 5vw',
    zIndex: '2',
  },
  centerImgDiv: {
    backgroundSize: 'contain',
    margin: ' 1.5vw 0 0 0',
    minHeight: '74vw',
    width: '92vw',
    borderRadius: '20px',
    position: 'relative',
  },
  centerImg: {
    width: '37vw',
    height: '32.5vw',
    margin: '0 0 0 1vw',
    borderRadius: '20px',
  },
  textDiv: {
    width: isMobile ? '92vw' : '30.5vw',
    backgroundColor: '#4B4B4B',
    margin: isMobile ? '1.5vw 0 0 0' : '0 0 0 1vw',
    borderRadius: isMobile ? '4.5vw' : '20px',
    padding: isMobile ? '4vw' : '2vw',
    minHeight: isMobile && '107vw',
  },
  content: {
    whiteSpace: 'pre-wrap',
    fontSize: isMobile ? '4.05vw' : '1.15vw',
    width: isMobile && '85vw',
    fontFamily: 'Gothic A1',
    fontWeight: '400',
    fontStyle: 'normal',
    color: '#fff',
    margin: '0',
    minHeight: isMobile ? '70vw' : '16vw',
  },
  mainTitle: {
    fontStyle: 'normal',
    fontSize: '1.6vw',
    color: '#fff',
    textTransform: 'uppercase',
    margin: '1vw 0 2.5vw 0',
    fontFamily: 'Gothic A1',
    fontWeight: '900',
  },
  subTitle: {
    fontFamily: 'Gothic A1',
    fontWeight: '900',
    fontStyle: 'normal',
    fontSize: isMobile ? '4.05vw' : '1.25vw',
    color: '#fff',
    textTransform: 'uppercase',
    margin: isMobile ? '7vw 0 5vw 0' : '0 0 0.8vw 0',
  },
  btn: {
    background: ' #FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
    width: isMobile ? '28.15vw' : '8vw',
    height: isMobile ? '9.4vw' : '2.6vw',
    marginTop: isMobile ? '1.5vw' : '2vw',
    border: 'none',
    outline: 'none',
    fontSize: isMobile ? '3.15vw' : '0.85vw',
    fontWeight: '500',
    cursor: 'pointer',
  },
  imgText: {
    position: 'absolute',
    left: '4vw',
    top: '58vw',
    fontFamily: 'Gothic A1',
    fontSize: '5.8vw',
    fontStyle: 'normal',
    fontWeight: '900',
    color: '#fff',
  },
  optionDiv: {
    textAlign: 'center',
    minHeight: !isMobile && '8vw',
    minWidth: isMobile && '25vw',
    zIndex: '2',
  },
};

export { carrouselStyles };
