import React, {useState} from 'react';
import { Data } from '../../Data/Home/shareholder.json';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../Images/AustralianLogo.png';
import markedDot from '../../Images/markedDot.png';
import unmarkedDot from '../../Images/unmarkedDot.png';
import { Col, Row } from 'react-bootstrap';


export default function Welcome() {
  const [animate, setAnimate] = useState(false)
  const useStyles = makeStyles({
    Text1: {
      paddingTop: isMobile ? '13vw' : '2vw',
      color: 'White',
      fontSize: isMobile ? '7vw' : '3vw',
      fontWeight: 900,
      textAlign: 'center',
      maxWidth: isMobile ? '' : '',
      fontFamily: 'Gothic A1'
    },
    FirstFadeIn: {
      position:"absolute",
      width:'100%',
      right: '0%',
      opacity: 1,
      transition: 'opacity 1s, right 1s'
    },
    FirstFadeOut: {
      position:"absolute",
      width:'100%',
      right:'100%',
      opacity: 0,
      transition: 'right 1s, opacity 0.5s'
    },
    SecondFadeIn: {
      position:"absolute",
      width:'100%',
      left:'0%',
      opacity: 1,
      transition: 'left 1s, opacity 1s'
    },
    SecondFadeOut: {
      position:"absolute",
      width:'100%',
      left:'100%',
      opacity: 0,
      transition: 'left 1s, opacity 0.5s'
    },
    Text2: {
      paddingRight: isMobile ? '3vw' : '5.5vw',
      paddingLeft: isMobile ? '3vw' : '5.5vw',
      color: 'white',
      fontSize: isMobile ? '5vw' : '1.5vw',
      fontWeight: 'normal',
      textAlign: 'center;',
      maxWidth: isMobile ? '90vw' : '62.4vw',
      fontFamily: 'Gothic A1',
    },

    blackRect1: {
      width: isMobile ? '92vw' : '5.3vw',
      height: isMobile ? '20vw' : '16.5vw',
      zIndex: '1',
      backgroundColor: '#4B4B4B',
      borderRadius: isMobile ? '5vw' : '1vw',
      marginRight: isMobile ? '' : '1vw',
      marginBottom: isMobile ? '2vw' : '',
    },
    blackRect2: {
      marginTop: isMobile ? '2vw' : '',

      width: isMobile ? '92vw' : '5.3vw',
      height: isMobile ? '20vw' : '16.5vw',
      zIndex: '0',
      backgroundColor: '#4B4B4B',
      borderRadius: isMobile ? '5vw' : '1vw',
      marginLeft: isMobile ? '' : '1vw',
    },
    orangeRect: {
      width: isMobile ? '92vw' : '62.4vw',
      height: isMobile ? '90vw' : '16.5vw',
      backgroundColor: '#FA9E23',
      borderRadius: isMobile ? '5vw' : '1vw',
      alignContent: "center",
      verticalAlign: 'center'
    },

    logo: {
      position: 'absolute',
      width: isMobile ? '20vw' : '6vw',
      zIndex: '2',
      left: isMobile ? '36vw' : '-3.5vw',
      top: isMobile ? '-11vw' : '5vw',
    },

    logo1: {
      position: 'absolute',
      width: isMobile ? '20vw' : '6vw',
      marginLeft: isMobile ? '' : '-3.5vw',
      zIndex: '2',
      right: isMobile ? '36vw' : '-3.5vw',
      top: isMobile ? '' : '5vw',
      bottom: isMobile ? '-11vw' : '',
    }
  });
  const classes = useStyles();
  const [firstText, setFirstText] = useState('')
  const [secondText, setSecondText] = useState('')
  const [index, setIndex] = useState(0)
  const [firstZindex, setFirstZindex] = useState(10)
  const [secondZindex, setSecondZindex] = useState(-10)
  const delay = 7000
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>{
        setIndex((prevIndex) =>
          prevIndex === Data.length - 1 ? 0 : prevIndex + 1
        )
      },
      delay
    );
    return () => {
      resetTimeout();
    };
  }, [index]);

  function website() {
    return (
      <div>
            <div
              style={{
                display: 'flex',
                height: isMobile ? '100vw' : '10vw',
                width: isMobile ? '92vw' : '75vw',
              }}
            >
                <div className={classes.blackRect1}></div>
                <div style={{ position: 'relative' }}>
                  <img className={classes.logo} src={logo} alt={''}></img>
                  <div className={classes.orangeRect}>
                    <Col>
                      <div style={{position:"absolute"}} className={index==0? classes.FirstFadeIn: classes.FirstFadeOut}>
                        <p className={classes.Text1}>{Data[0].Title}</p>
                        <p className={classes.Text2}>{Data[0].Body}</p>
                      </div>
                      <div style={{position:"absolute"}} className={index==1? classes.SecondFadeIn: classes.SecondFadeOut}>
                        <p className={classes.Text1}>{Data[1].Title}</p>
                        <p className={classes.Text2}>{Data[1].Body}</p>
                      </div>
                      <div style={{position:"absolute", left:'47.5%', paddingTop:'23%', zIndex:'20'}}>
                        <img src={index == 0? markedDot: unmarkedDot} style={{width: '1.1vw', cursor: 'pointer'}} onClick={()=> {
                          setIndex(0)
                          resetTimeout();
                          }} alt={''}></img>
                        <img src={index == 0? unmarkedDot: markedDot} style={{width: '1.1vw', marginLeft: '0.55vw',  cursor: 'pointer'}} onClick={()=> {
                          setIndex(1)
                          resetTimeout();
                          }} alt={''}></img>
                      </div>
                    </Col>
                  </div>
                  <img className={classes.logo1} src={logo} alt={''}></img>
                </div>
                <div className={classes.blackRect2}></div>
            </div>
      </div>
    );
  }
  function mobile() {
    return (
      <div>
            <div style={{ width: isMobile ? '92vw' : '75vw' }}>
              <div>
                <div className={classes.blackRect1}> </div>

                <div
                  className={classes.orangeRect}
                  style={{ position: 'relative' }}
                >
                  <Col>
                      <div style={{position:"absolute"}} className={index==0? classes.FirstFadeIn: classes.FirstFadeOut}>
                        <p className={classes.Text1}>{Data[0].Title}</p>
                        <p className={classes.Text2}>{Data[0].Body}</p>
                      </div>
                      <div style={{position:"absolute"}} className={index==1? classes.SecondFadeIn: classes.SecondFadeOut}>
                        <p className={classes.Text1}>{Data[1].Title}</p>
                        <p className={classes.Text2}>{Data[1].Body}</p>
                      </div>
                      <div style={{position:"absolute", left:'47%', top:'75vw', zIndex:'20'}}>
                        <img src={index == 0? markedDot: unmarkedDot} style={{width: '2.53vw',  cursor: 'pointer'}} onClick={()=> {
                          setIndex(0)
                          resetTimeout();
                          }} alt={''}></img>
                        <img src={index == 0? unmarkedDot: markedDot} style={{width: '2.53vw', marginLeft: '0.55vw',  cursor: 'pointer'}} onClick={()=> {
                          setIndex(1)
                          resetTimeout();
                          }} alt={''}></img>
                      </div>
                    </Col>
                  <img className={classes.logo} src={logo} alt={''}></img>
                  <img className={classes.logo1} src={logo} alt={''}></img>
                </div>

                <div className={classes.blackRect2}></div>
              </div>
            </div>
      </div>
    );
  }

  return <div>
    {isMobile ? mobile() : website()}
    </div>;
}
