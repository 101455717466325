const Data = {
  title: "QUALITY ASSURANCE",
  paragraph1:
    "We don’t follow the rule of one-size-fits-all. Lift design is important to us at Brilliant and that’s why we offer a personal solution to your specific requirements. This makes our products durable as they are uniquely made to fit your logistical and functional needs.",
  paragraph2:
    "In order to prove to ourselves and to our clientele that our Products & Services are reliable we strive to acquire and to adhere to certifications that ensure our commitment to quality. Moreover, Ride Quality at Brilliant is of great significance because the operation of the elevator goes through a stringent 100 point test before we put any lift into service.",
  paragraph3:
    "Our highly qualified technical team can advise you on the most suitable product that fits your building requirements, and most importantly, your budget. Trust in BLA to provide you with not only durable and reliable products and services, but also value for money.",
  subtitle1: "TOTAL INSTALLATIONS",
  elevator: "788",
  subtitle2: "TOTAL EMPLOYEES",
  employees: "50,232",
};

module.exports = {
  Data,
};
