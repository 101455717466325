import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import BedliftImg from '../../Images/commercialElevators-Bedlift.png';
import BedliftImgMobile from '../../Images/commercialElevators-BedliftMobile.png';
import PanoramicImg from '../../Images/commercialElevators-Panoramic.png';
import PanoramicImgMobile from '../../Images/commercialElevators-PanoramicMobile.png';
import PassengerImg from '../../Images/commercialElevators-Passenger.png';
import PassengerImgMobile from '../../Images/commercialElevators-PassengerMobile.png';
import centerImg from '../../Images/commercialElevators-centerImg.png';
import centerImgMobile from '../../Images/commercialElevators-centerImgMobile.png';
import commercialPassenger from '../../Images/commercial_passenger.png';
import commercialBed from '../../Images/commercial_bed.png';
import commercialPanoramic from '../../Images/residential_panoramic.png';
import data from '../../Data/Products/CommercialElevatorsCarrousel.json';
import { carrouselStyles } from './CarrouselStyles';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

const useStyles = makeStyles(carrouselStyles);

export default function Carrousel() {
  const styles = useStyles();
  const [selected, setSelected] = useState('panoramic');
  const history = useHistory();
  const selectedData =
    selected === 'panoramic'
      ? data.Panoramic
      : selected === 'passenger'
      ? data.Passenger
      : data.BedLift;

  return (
    <div style={{ margin: '4vw' }} className={styles.container}>
      <div className={styles.carrousel}>
        <div className={styles.optionDiv}>
          {selected === 'bedlift' ? (
            <img
              alt=''
              src={isMobile ? BedliftImgMobile : BedliftImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('bedlift')}
            >
              bed lift
            </p>
          )}
        </div>
        <div className={styles.optionDiv}>
          {selected === 'panoramic' ? (
            <img
              alt=''
              src={isMobile ? PanoramicImgMobile : PanoramicImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('panoramic')}
            >
              panoramic
            </p>
          )}
        </div>
        <div className={styles.optionDiv}>
          {selected === 'passenger' ? (
            <img
              alt=''
              src={isMobile ? PassengerImgMobile : PassengerImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('passenger')}
            >
              passenger
            </p>
          )}
        </div>
      </div>
      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),
      url(${
        selected === 'passenger'
          ? commercialPassenger
          : selected === 'panoramic'
          ? commercialPanoramic
          : commercialBed
      })`,
          }}
        >
          <span className={styles.imgText}>Commercial Elevators</span>
        </div>
      ) : (
        <img
          src={
            selected === 'passenger'
              ? commercialPassenger
              : selected === 'panoramic'
              ? commercialPanoramic
              : commercialBed
          }
          alt=''
          className={styles.centerImg}
        />
      )}
      <div className={styles.textDiv}>
        {isMobile ? null : (
          <h2 className={styles.mainTitle}>Commercial Elevators</h2>
        )}
        <h4 className={styles.subTitle}>{selectedData.title}</h4>
        <pre className={styles.content}>{parse(selectedData.content)}</pre>
        <button
          className={styles.btn}
          onClick={() => {
            history.push({ pathname: '/contactus', state: 'contact2' });
          }}
        >
          ENQUIRE
        </button>
      </div>
    </div>
  );
}
