import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import LearnMore from '../Components/Services/LearnMore';
import Trustworthy from '../Components/Services/Trustworthy';
import QualityCards from '../Components/Services/QualityCards';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  main: {
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
  },
  LearnMore: { marginTop: isMobile ? '25vw' : '8vw' },
  Trustworthy: { marginTop: isMobile ? '10vw' : '5vw' },
  QualityCards: { marginTop: isMobile ? '20vw' : '8vw' },
});

export default function Services() {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    const element = document.getElementById('quality-cards');
    if (element) {
      if (history.location.state === 'planned-maintenance')
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      else if (history.location.state === 'communication')
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
    }
  }, []);

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className={classes.main}>
        <div className={classes.Trustworthy}>
          <Trustworthy />
        </div>
        <div className={classes.QualityCards} id='quality-cards'>
          <QualityCards />
        </div>
      </div>
      <div className={classes.LearnMore}>
        <LearnMore />
      </div>
    </div>
  );
}
