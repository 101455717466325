import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'

export default function Form(props) {
  const isDark = props.isDark
  const [enquiryInput, setEnquiryInput] = useState({
    name: '',
    phone: '',
    email: '',
    enquiry: '',
  })

  const [enquiryInputError, setEnquiryInputError] = useState({
    name: '',
    phone: '',
    email: '',
    enquiry: '',
  })

  const [enquiryInputBorder, setEnquiryInputBorder] = useState({
    name: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
    phone: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
    email: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
    enquiry: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
  })

  useEffect(() => {
    setEnquiryInputBorder({
      name: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
      phone: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
      email: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
      enquiry: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
    })
  }, [props, isDark])

  const useStyles = makeStyles({
    outerDiv: {
      marginTop: isMobile ? '-5vw' : '',
      width: isMobile ? '92vw' : '37.5vw',
      height: isMobile ? '160vw' : '46vw',
      backgroundColor: isDark ? '#4B4B4B' : '#fff',
      boxShadow: isMobile
        ? '0px 0px 1.35vw rgba(0, 0, 0, 0.25)'
        : '0px 0px 0.3125vw rgba(0, 0, 0, 0.25)',
      borderRadius: isMobile ? '0px 0px 20px 20px' : '0px 1.04vw 1.04vw 0px',
      textAlign: isMobile ? 'left' : 'center',
      transition: 'background-color 1s ease',
      WebkitTransition: 'background-color 1s ease',
      MozTransition: 'background-color 1s ease',
    },
    innerDiv: {
      width: isMobile ? '78.8vw' : '28.3vw',
      margin: isMobile ? '4vw' : '0 auto',
    },
    title: {
      fontFamily: 'Gothic A1',
      fontSize: isMobile ? '7vw' : '3vw',
      color: isDark ? '#fff' : '#FA9E23',
      paddingTop: isMobile ? '18vw' : '2.7vw',
      textAlign: isMobile ? '' : 'left',
      fontWeight: '900',
    },
    input: {
      marginTop: isMobile ? '' : '2vw',
      fontFamily: 'Gothic A1',
      width: isMobile ? '78.8vw' : '28.3vw',
      height: isMobile ? '15vw' : '2.2vw',
      backgroundColor: 'transparent',
      outline: 'none',
      borderTopStyle: 'none',
      borderBottomStyle: 'solid',
      borderRightStyle: 'none',
      borderLeftStyle: 'none',
      borderWidth: '3px',
      color: isDark ? '#fff' : '#4B4B4B',
      padding: isMobile ? '0 0 3vw 0' : '0 0 0.5vw 0',
      fontWeight: '400',
      fontSize: isMobile ? '5vw' : '1.2vw',
      fontStyle: 'bold',
      '&::-webkit-input-placeholder': {
        opacity: '0.7',
      },
    },
    errorDiv: {
      width: isMobile ? '78.8vw' : '28.3vw',
      height: isMobile ? '7vw' : '2vw',
      textAlign: 'right',
      margin: '0 auto',
      paddingTop: isMobile ? '2vw' : '0.5vw',
      color: '#B40E00',
      fontWeight: 400,
      fontFamily: 'Gothic A1',
      fontSize: isMobile ? '3.6vw' : '0.83vw',
    },
    error: {
      margin: '0',
    },
    submitBtn: {
      width: isMobile ? '28vw' : '8vw',
      height: isMobile ? '9vw' : '2.6vw',
      background: isDark ? '#FFFFFF' : '#FA9E23',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      marginTop: isMobile ? '11vw' : '3.5vw',
      color: isDark ? '#4B4B4B' : '#fff',
      fontWeight: 600,
      fontFamily: 'Gothic A1',
      fontSize: isMobile ? '3.6vw' : '0.85vw',
    },
  })

  const styles = useStyles()

  const validateData = () => {
    const errorMsgs = {
      email: '',
      name: '',
      phone: '',
      enquiry: '',
    }
    let validationError = false
    if (
      !enquiryInput.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      errorMsgs.email = 'Invalid email format.'
      validationError = true
    }

    if (!enquiryInput.name.match(/^[a-zA-Z\s]+$/)) {
      errorMsgs['name'] = 'Name can only have alphabets.'
      validationError = true
    }

    if (enquiryInput.name.length <= 2) {
      errorMsgs['name'] = 'Name must have more than two characters.'
      validationError = true
    }

    if (enquiryInput.enquiry.length <= 2) {
      errorMsgs['enquiry'] = 'Enquiry must have more than two characters.'
      validationError = true
    }

    if (!enquiryInput.phone.match(/^[0-9]+$/)) {
      errorMsgs.phone = 'Phone must have numbers only.'
      validationError = true
    }

    setEnquiryInputError(errorMsgs)
    return validationError
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    let allFilled = true
    const emptyFieldsBorders = {}
    for (const attribute in enquiryInput) {
      if (enquiryInput[attribute].length === 0) {
        emptyFieldsBorders[attribute] = '#B40E00'
        allFilled = false
      }
    }

    setEnquiryInputBorder(emptyFieldsBorders)

    const validationError = validateData()

    if (allFilled && !validationError) {
      // console.log({ enquiryInput })
      axios({
        method: 'post',
        data: {
          receiverEmail: ['info@brilliantlifts.com.au'],
          body: `Dears,

The following customer has inquired via the website form:

name: ${enquiryInput.name},
phone: ${enquiryInput.phone},
email: ${enquiryInput.email},
message: ${enquiryInput.enquiry}
          `,
          subject: 'New Inquiry',
          password: "mpN'y5{jN,9q{H4:",
        },
        url: 'https://emailservice.sitone.lirtenhub.com/sendemail',
      })
      setEnquiryInput({
        name: '',
        phone: '',
        email: '',
        enquiry: '',
      })
      setEnquiryInputBorder({
        name: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
        phone: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
        email: isDark ? 'rgba(255, 255, 255, 0.3)' : 'rgba(250, 158, 35, 0.3)',
        enquiry: isDark
          ? 'rgba(255, 255, 255, 0.3)'
          : 'rgba(250, 158, 35, 0.3)',
      })
    }
  }

  const handleBlur = (e) => {
    const { name, value } = e.target
    setEnquiryInputBorder((prevState) => {
      return {
        ...prevState,
        [name]: value.length === 0 ? '#B40E00' : isDark ? '#fff' : '#FA9E23',
      }
    })

    const errorMsgs = {
      email: '',
      name: '',
      phone: '',
      enquiry: '',
    }
    setEnquiryInputError(errorMsgs)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setEnquiryInput((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const handleFocus = (e) => {
    const { name } = e.target
    setEnquiryInputBorder((prevState) => {
      return {
        ...prevState,
        [name]: isDark ? '#fff' : '#FA9E23',
      }
    })
  }

  return (
    <div className={styles.outerDiv}>
      <div className={styles.innerDiv}>
        <h1 className={styles.title}>ONLINE ENQUIRY</h1>
        {/* Name Input */}
        <input
          className={styles.input}
          required
          value={enquiryInput.name}
          placeholder='Name*'
          name='name'
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          style={{ borderColor: enquiryInputBorder.name }}
        />
        <div className={styles.errorDiv}>
          {enquiryInputBorder.name === '#B40E00' ? (
            <p className={styles.error}>This field is required.</p>
          ) : enquiryInputError.name.length != 0 ? (
            enquiryInputError.name
          ) : null}
        </div>
        {/* Phone Input */}
        <input
          className={styles.input}
          required
          value={enquiryInput.phone}
          placeholder='Phone*'
          name='phone'
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          style={{ borderColor: enquiryInputBorder.phone }}
        />
        <div className={styles.errorDiv}>
          {enquiryInputBorder.phone === '#B40E00' ? (
            <p className={styles.error}>This field is required.</p>
          ) : enquiryInputError.phone.length != 0 ? (
            enquiryInputError.phone
          ) : null}
        </div>
        {/* Email Input */}
        <input
          className={styles.input}
          required
          value={enquiryInput.email}
          placeholder='Email*'
          name='email'
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          style={{ borderColor: enquiryInputBorder.email }}
        />
        <div className={styles.errorDiv}>
          {enquiryInputBorder.email === '#B40E00' ? (
            <p className={styles.error}>This field is required.</p>
          ) : enquiryInputError.email.length != 0 ? (
            enquiryInputError.email
          ) : null}
        </div>
        {/* Enquiry Input */}
        <input
          className={styles.input}
          required
          value={enquiryInput.enquiry}
          placeholder='Please enter your enquiry*'
          name='enquiry'
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          style={{ borderColor: enquiryInputBorder.enquiry }}
        />
        <div className={styles.errorDiv}>
          {enquiryInputBorder.enquiry === '#B40E00' ? (
            <p className={styles.error}>This field is required.</p>
          ) : enquiryInputError.enquiry.length != 0 ? (
            enquiryInputError.enquiry
          ) : null}
        </div>
        <div style={{ textAlign: 'right' }}>
          <button onClick={handleSubmit} className={styles.submitBtn}>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  )
}
