import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import fabGrey from '../../Images/fabGrey.png';
import fabOrange from '../../Images/fabOrange.png';
import lineV from '../../Images/LineV.png';
import lineV2 from '../../Images/lineV2.png';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';
import { isMobile } from 'react-device-detect';
import { Data } from '../../Data/Home/Glance.json';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginLeft: isMobile ? '2.5vw' : '14vw',
    // marginTop: '15vw',
    borderWidth: isMobile ? '1.8vw' : '0.55vw',
    borderStyle: 'solid',
    borderRadius: isMobile ? '4vw' : '1vw',
    width: isMobile ? '92vw' : '75vw',
    height: isMobile ? '125vw' : '22vw',
    overflow: 'hidden',
    borderColor: '#FA9E23',
    backgroundImage: isMobile ? `url(${lineV2})` : `url(${lineV})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '14vw',
    backgroundSize: 'contain',
  },
  textDiv: {
    fontSize: isMobile ? '4.5vw' : '1.5vw',
    alignSelf: 'center',
    fontFamily: 'Gothic A1',
    width: isMobile ? '61vw' : '44vw',
    height: isMobile ? '32vw' : '7vw',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    marginRight: isMobile ? '2.5vw' : '2.75vw',
    marginLeft: isMobile ? '2.5vw' : '6.05vw',
    textAlign: 'initial',
    marginTop: isMobile ? '-13vw' : '',
  },
  mainDiv: {
    justifyContent: 'flex-end',
    width: isMobile ? '92vw' : '72vw',
    height: isMobile ? '45vw' : '7vw',
    paddinRight: isMobile ? '3vw' : '',

    display: 'flex',
  },
  fabMain: {
    animation: `$mainEffectNext 500ms ${theme.transitions.easing.easeInOut}`,

    height: isMobile ? '22vw' : '6vw',
    width: isMobile ? '22vw' : '6vw',
    alignSelf: 'center',
  },
  fabMainNext: {
    animation: `$mainEffectNext 500ms ${theme.transitions.easing.easeInOut}`,

    height: isMobile ? '22vw' : '6vw',
    width: isMobile ? '22vw' : '6vw',
    alignSelf: 'center',
  },
  fabMainPrev: {
    animation: `$mainEffectPrev 500ms ${theme.transitions.easing.easeInOut}`,

    height: isMobile ? '22vw' : '6vw',
    width: isMobile ? '22vw' : '6vw',
    alignSelf: 'center',
  },
  fabMainNext1: {
    animation: `$mainEffectNext1 500ms ${theme.transitions.easing.easeInOut}`,

    height: isMobile ? '22vw' : '6vw',
    width: isMobile ? '22vw' : '6vw',
    alignSelf: 'center',
  },
  fabMainPrev1: {
    animation: `$mainEffectPrev1 500ms ${theme.transitions.easing.easeInOut}`,

    height: isMobile ? '22vw' : '6vw',
    width: isMobile ? '22vw' : '6vw',
    alignSelf: 'center',
  },
  fabSecond: {
    cursor: 'pointer',
    height: isMobile ? '13.7vw' : '3.7vw',
    width: isMobile ? '13.7vw' : '3.7vw',
    alignSelf: 'center',
  },
  fabSecondNext: {
    animation: `$secondEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    cursor: 'pointer',
    height: isMobile ? '13.7vw' : '3.7vw',
    width: isMobile ? '13.7vw' : '3.7vw',
    alignSelf: 'center',
  },
  fabSecondNext1: {
    animation: `$secondEffectNext1 500ms ${theme.transitions.easing.easeInOut}`,
    cursor: 'pointer',
    height: isMobile ? '13.7vw' : '3.7vw',
    width: isMobile ? '13.7vw' : '3.7vw',
    alignSelf: 'center',
  },
  fabSecondPrev: {
    animation: `$secondEffectPrev 500ms ${theme.transitions.easing.easeInOut}`,
    cursor: 'pointer',
    height: isMobile ? '13.7vw' : '3.7vw',
    width: isMobile ? '13.7vw' : '3.7vw',
    alignSelf: 'center',
  },
  fabSecondPrev1: {
    animation: `$secondEffectPrev1 500ms ${theme.transitions.easing.easeInOut}`,
    cursor: 'pointer',
    height: isMobile ? '13.7vw' : '3.7vw',
    width: isMobile ? '13.7vw' : '3.7vw',
    alignSelf: 'center',
  },
  fabCont: {
    // animation: `$secondEffectPrev 500ms ${theme.transitions.easing.easeInOut}`,

    position: 'relative',
    height: isMobile ? '32vw' : '7vw',
    width: isMobile ? '22vw' : '10vw',
    justifyContent: 'center',
    display: 'flex',
  },
  spanSecond: {
    animation: `$appear 800ms ${theme.transitions.easing.easeInOut}`,
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'Gothic A1',
    position: 'absolute',
    fontSize: isMobile ? '5vw' : '1vw',
    top: isMobile ? '13.5vw' : '2.9vw',
    cursor: 'pointer',
  },
  spanMain: {
    animation: `$appear 600ms ${theme.transitions.easing.easeInOut}`,
    color: 'white',
    fontFamily: 'Gothic A1',
    position: 'absolute',
    fontSize: isMobile ? '8.6vw' : '3.6vw',
    fontWeight: '600',
    top: isMobile ? '11vw' : '1.1vw',
  },
  spanSecond1: {
    fontWeight: 'bold',

    animation: `$appear1 800ms ${theme.transitions.easing.easeInOut}`,

    color: 'white',
    fontFamily: 'Gothic A1',
    position: 'absolute',
    fontSize: isMobile ? '5vw' : '1vw',
    top: isMobile ? '13.5vw' : '2.9vw',
    cursor: 'pointer',
  },
  spanMain1: {
    animation: `$appear1 600ms ${theme.transitions.easing.easeInOut}`,
    color: 'white',
    fontFamily: 'Gothic A1',
    position: 'absolute',
    fontSize: isMobile ? '8.6vw' : '3.6vw',
    fontWeight: '600',
    top: isMobile ? '11vw' : '1.1vw',
  },
  textMain: {
    animation: `$mainEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    color: '#4B4B4B',
  },
  textSecond: {
    animation: `$secondEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.5,
    color: '#4B4B4B',
    cursor: 'pointer',
  },
  textMainNext: {
    animation: `$mainEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    color: '#4B4B4B',
  },
  textSecondNext: {
    animation: `$secondEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.5,
    color: '#4B4B4B',
    cursor: 'pointer',
  },
  textMainPrev: {
    animation: `$mainEffectPrev 500ms ${theme.transitions.easing.easeInOut}`,
    color: '#4B4B4B',
  },
  textSecondPrev: {
    animation: `$secondEffectPrev 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.5,
    color: '#4B4B4B',
    cursor: 'pointer',
  },
  textMainNext1: {
    animation: `$mainEffectNext1 800ms ${theme.transitions.easing.easeInOut}`,
    color: '#4B4B4B',
  },
  textSecondNext1: {
    animation: `$secondEffectNext1 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.5,
    color: '#4B4B4B',
    cursor: 'pointer',
  },
  textMainPrev1: {
    animation: `$mainEffectPrev1 500ms ${theme.transitions.easing.easeInOut}`,
    color: '#4B4B4B',
  },
  textSecondPrev1: {
    animation: `$secondEffectPrev1 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.5,
    color: '#4B4B4B',
    cursor: 'pointer',
  },
  animatedItem: {
    animation: `$mainEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$secondEffectNext 500ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
    transform: 'translateY(-200%)',
  },
  '@keyframes mainEffectNext': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-300%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectNext': {
    '0%': {
      opacity: 1,
      transform: 'translateY(200%)',
    },
    '25%': {
      transform: 'translateY(400%)',
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(-200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes mainEffectPrev': {
    '0%': {
      opacity: 0.5,
      transform: 'translateY(300%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectPrev': {
    '0%': {
      opacity: 1,
      transform: 'translateY(-200%)',
    },
    '25%': {
      transform: 'translateY(-400%)',
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectPrevKarim': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-200%)',
    },
    '25%': {
      opacity: 0,

      transform: 'translateY(-400%)',
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes mainEffectNext1': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-300%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectNext1': {
    '0%': {
      opacity: 1,
      transform: 'translateY(200%)',
    },
    '25%': {
      transform: 'translateY(400%)',
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(-200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes mainEffectPrev1': {
    '0%': {
      opacity: 0.5,
      transform: 'translateY(300%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectPrev1': {
    '0%': {
      opacity: 1,
      transform: 'translateY(-200%)',
    },
    '25%': {
      transform: 'translateY(-400%)',
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes secondEffectPrevKarim1': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-200%)',
    },
    '25%': {
      opacity: 0,

      transform: 'translateY(-400%)',
    },
    '40%': {
      opacity: 0,
    },
    '75%': {
      opacity: 0.5,

      transform: 'translateY(200)',
    },
    '100%': {
      opacity: 0.5,

      transform: 'translateY(0)',
    },
  },
  '@keyframes appear': {
    '0%': {
      opacity: 0,
    },
    '40%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes appear1': {
    '0%': {
      opacity: 0,
    },
    '40%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export default function Glance1() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [next, setNext] = React.useState(0);
  const [nextHelper, setNextHelper] = React.useState(true);

  var step = Data;
  var nums = Array.from({ length: step.length }, (_, i) => i + 1);

  const [steps, setsteps] = React.useState(step);
  const [stepsNum, setstepsNum] = React.useState(nums);
  useEffect(() => {}, [activeStep, stepsNum, steps]);

  const classes = useStyles();

  const handleNext = () => {
    if (next !== -1) {
      setNext(-1);
    } else {
      setNext(-2);
    }
    // if (next !== 1) {
    //   setNext(1);
    // } else {
    //   setNext(2);
    // }
    setNextHelper(!nextHelper);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    var x = steps.shift();
    steps.push(x);
    setsteps(steps);
    var y = stepsNum.shift();
    stepsNum.push(y);
    setstepsNum(stepsNum);
  };
  const handleScroll = () => {};

  const handleBack = () => {
    setNextHelper(!nextHelper);
    if (next !== 1) {
      setNext(1);
    } else {
      setNext(2);
    }
    // if (next !== -1) {
    //   setNext(-1);
    // } else {
    //   setNext(-2);
    // }
    var x = steps.pop();
    steps.unshift(x);
    setsteps(steps);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    var y = stepsNum.pop();
    stepsNum.unshift(y);
    setstepsNum(stepsNum);
  };

  return (
    <div>
      <div
        style={{
          width: isMobile ? '95vw' : '72.2vw',
          // paddingLeft: isMobile ? '3vw' : '12.5vw',
          // paddingRight: isMobile ? '3vw' : '12.5vw',
          fontSize: isMobile ? '8vw' : '3vw',
          fontFamily: 'Gothic A1',
          color: '#FA9E23',
          marginBottom: '1vw',
          fontWeight: '900',
          textAlign: isMobile ? '' : 'center',
        }}
      >
        AT A GLANCE
      </div>
      <ReactScrollWheelHandler upHandler={handleBack} downHandler={handleNext}>
        <div className={classes.root} onScroll={handleScroll}>
          {steps.map((label, index) => (
            <div className={classes.mainDiv}>
              <div className={classes.fabCont}>
                {index === 1 ? (
                  <span
                    className={
                      nextHelper ? classes.spanMain1 : classes.spanMain
                    }
                  >
                    {stepsNum[index]}
                  </span>
                ) : index < 1 ? (
                  <span
                    className={
                      nextHelper ? classes.spanSecond : classes.spanSecond1
                    }
                    onClick={handleBack}
                  >
                    {stepsNum[index]}
                  </span>
                ) : (
                  <span
                    className={
                      nextHelper ? classes.spanSecond : classes.spanSecond1
                    }
                    onClick={handleNext}
                  >
                    {stepsNum[index]}
                  </span>
                )}
                {index === 1 ? (
                  <img
                    className={
                      next === 1
                        ? classes.fabMainNext
                        : next === 2
                        ? classes.fabMainNext1
                        : next === -1
                        ? classes.fabMainPrev
                        : classes.fabMainPrev1
                    }
                    src={fabGrey}
                    alt='logo'
                  />
                ) : index < 1 ? (
                  <img
                    className={
                      next === 1
                        ? classes.fabSecondNext
                        : next === 2
                        ? classes.fabSecondNext1
                        : next === -1
                        ? classes.fabSecondPrev
                        : classes.fabSecondPrev1
                    }
                    onClick={handleBack}
                    src={fabOrange}
                    alt='logo'
                  />
                ) : (
                  <img
                    className={
                      next === 1
                        ? classes.fabSecondNext
                        : next === 2
                        ? classes.fabSecondNext1
                        : next === -1
                        ? classes.fabSecondPrev
                        : classes.fabSecondPrev1
                    }
                    onClick={handleNext}
                    src={fabOrange}
                    alt='logo'
                  />
                )}
              </div>

              <div className={classes.textDiv}>
                {index === 1 ? (
                  <span
                    className={
                      next === 1
                        ? classes.textMainNext
                        : next === 2
                        ? classes.textMainNext1
                        : next === -1
                        ? classes.textMainPrev
                        : classes.textMainPrev1
                    }
                  >
                    {label}
                  </span>
                ) : index < 1 ? (
                  <span
                    className={
                      next === 1
                        ? classes.textSecondNext
                        : next === 2
                        ? classes.textSecondNext1
                        : next === -1
                        ? classes.textSecondPrev
                        : classes.textSecondPrev1
                    }
                    onClick={handleBack}
                  >
                    {label}
                  </span>
                ) : (
                  <span
                    className={
                      next === 1
                        ? classes.textSecondNext
                        : next === 2
                        ? classes.textSecondNext1
                        : next === -1
                        ? classes.textSecondPrev
                        : classes.textSecondPrev1
                    }
                    onClick={handleNext}
                  >
                    {label}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      </ReactScrollWheelHandler>
    </div>
  );
}
