import React from 'react'
import qualityAssuranceImage from '../../Images/qualityAssuranceImage.png'
import OrangeTick from '../../Images/OrangeTick.png'
import qualityAssuranceElevators from '../../Images/qualityAssuranceElevators.png'
// import qualityAssuranceEmployees from "../../Images/qualityAssuranceEmployees.png";
import certificate1Mob from '../../Images/certificate1Mob.png'
import certificate2Mob from '../../Images/certificate2Mob.png'
import certificate1Web from '../../Images/certificate1Web.png'
import certificate2Web from '../../Images/certificate2Web.png'
import { Data } from '../../Data/AboutUs/QualityAssurance'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'html-react-parser'

const useStyles = makeStyles({
  general: {
    marginLeft: isMobile ? '2.5vw' : '12.5vw',
    marginRight: isMobile ? '2.5vw' : '12.5vw',
    width: isMobile ? '95vw' : '75.5vw',
    marginBottom: isMobile ? '19vw' : '8vw',
  },
  imgTextDiv: {
    fontSize: isMobile ? '' : '1.3vw',
    lineHeight: isMobile ? '' : '1.7vw',
    color: '#4B4B4B',
    display: isMobile ? '' : 'flex',
  },
  title: {
    color: '#FA9E23',
    fontSize: isMobile ? '6.5vw' : '3vw',
    fontWeight: '900',
    textAlign: 'Left',
    fontFamily: 'Gothic A1',
    marginBottom: isMobile ? '6vw' : '2vw',
  },
  textRow: {
    display: 'flex',
    marginTop: isMobile ? '10vw' : '4vw',
  },
  paragraph1: {
    color: '#4B4B4B',
    textAlign: 'Left;',
    fontFamily: 'Gothic A1',
    // paddingLeft: "4vw",
    marginBottom: isMobile ? '7vw' : '1.6vw',
    fontSize: isMobile ? '4vw' : '1.16vw',
    padding: 0,
  },
  paragraph2: {
    color: '#4B4B4B',

    textAlign: 'Left;',
    fontFamily: 'Gothic A1',
    //paddingLeft: "4vw",
    marginBottom: isMobile ? '7vw' : '1.6vw',
    fontSize: isMobile ? '4vw' : '1.16vw',
    padding: 0,
  },
  paragraph3: {
    color: '#4B4B4B',
    textAlign: 'Left;',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? '4vw' : '1.16vw',
    padding: 0,
  },
  Image: {
    width: isMobile ? '95vw' : '37vw',
    height: isMobile ? '95vw' : '37vw',
  },
  tick: {
    marginLeft: isMobile ? '0' : '1vw',
    marginRight: isMobile ? '2.5vw' : '2.2vw',
    height: isMobile ? '9vw' : '2.5vw',
    width: isMobile ? '9vw' : '2.5vw',
    marginTop: isMobile ? '1.5vw' : '0.2vw',
  },
  divs: {
    width: isMobile ? '93vw' : '75vw',

    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: isMobile ? '5vw' : '1vw',
    display: isMobile ? '' : 'flex',
    marginTop: isMobile ? '19vw' : '8vw',
    marginBottom: isMobile ? '19vw' : '8vw',
    marginLeft: isMobile ? '1vw' : '',
    marginRight: isMobile ? '0' : '',
  },
  certificateMob1: {
    height: '100vw',
    marginTop: '15vw',
    marginBottom: '15vw',
    marginRight: '15vw',
  },
  certificateMob2: {
    height: '100vw',
    marginTop: '15vw',
    marginBottom: '15vw',
  },
  certificateWeb: { width: '21vw', marginTop: '1.2vw', marginBottom: '1vw' },
  elevatorIcon: {
    width: isMobile ? '31vw' : '6.8vw',
    marginRight: isMobile ? '' : '0',
    marginLeft: isMobile ? '' : '0',
    marginTop: isMobile ? '8vw' : '2vw',
    marginBottom: isMobile ? '4vw' : '0.7vw',
  },
  employeesIcon: {
    width: isMobile ? '31vw' : '6.8vw',
    marginRight: isMobile ? '30vw' : '4.7vw',
    marginLeft: isMobile ? '30vw' : '4.3vw',
    marginTop: isMobile ? '8vw' : '2vw',
    marginBottom: isMobile ? '4vw' : '0.7vw',
  },
  LineDivider: {
    borderLeft: isMobile ? 'unset' : '0.3vw solid #4B4B4B',

    borderBottom: isMobile ? '0.5vw solid #4B4B4B' : 'unset',
    height: isMobile ? '6px' : '13vw',
    width: isMobile ? '88.4vw' : '6px',
    borderRadius: '10vw',
    marginLeft: '2.3vw',
    marginRight: '3.6vw',
    marginTop: '1.5vw',
    marginBottom: '1vw',
    padding: 0,
  },
  elevator: {
    marginLeft: isMobile ? '' : '',
    color: '#FA9E23',
    fontSize: isMobile ? '7vw' : '1.6vw',
    fontFamily: 'Gothic A1',
    textAlign: 'center',
  },
  elevatorNumber: {
    marginLeft: isMobile ? '' : '',
    marginTop: isMobile ? '0vw' : '0vw',
    marginBottom: isMobile ? '4vw' : '1.6vw',
    color: '#4B4B4B',
    fontSize: isMobile ? '7vw' : '1.6vw',
    fontFamily: 'Gothic A1',
    fontWeight: '900',
    textAlign: 'center',
  },
  employees: {
    marginLeft: isMobile ? '15vw' : '0vw',
    color: '#FA9E23',
    fontSize: isMobile ? '7vw' : '1.6vw',
    textAlign: 'Left',
    fontFamily: 'Gothic A1',
  },
  employeesNumber: {
    marginLeft: isMobile ? '33vw' : '5.2vw',
    marginTop: isMobile ? '0vw' : '0vw',
    marginBottom: isMobile ? '6vw' : '1.8vw',
    color: '#4B4B4B',
    fontSize: isMobile ? '7vw' : '1.6vw',
    textAlign: 'Left',
    fontFamily: 'Gothic A1',
    fontWeight: '900',
  },
  container: {
    marginLeft: isMobile ? '' : '-2vw',
    width: isMobile ? '' : '41vw',
  },
  centeredCounter: {
    width: isMobile ? '' : '47vw',
    textAlign: 'center',
  },
})

export default function QualityAssurance() {
  const classes = useStyles()

  return (
    <div id='quality' className={classes.general}>
      <div className={classes.title}>{Data.title}</div>
      <div className={classes.imgTextDiv}>
        {' '}
        <img src={qualityAssuranceImage} alt='' className={classes.Image} />
        <div>
          <div
            className={classes.textRow}
            style={{ marginTop: isMobile ? '10vw' : '0' }}
          >
            <img src={OrangeTick} alt='' className={classes.tick} />

            <div>{parse(Data.paragraph1)}</div>
          </div>
          <div className={classes.textRow}>
            <img src={OrangeTick} alt='' className={classes.tick} />
            <div>{parse(Data.paragraph2)}</div>
          </div>
          <div className={classes.textRow}>
            <img src={OrangeTick} alt='' className={classes.tick} />
            <div>{parse(Data.paragraph3)}</div>
          </div>
        </div>
      </div>
      {/* <div className={classes.divs}>
        <div className={classes.centeredCounter}>
          <img
            src={qualityAssuranceElevators}
            alt=""
            className={classes.elevatorIcon}
          />
          <div className={classes.elevator}>{Data.subtitle1}</div>
          <div className={classes.elevatorNumber}>{Data.elevator}</div>
        </div>{" "}
        {/* <div>
          <img
            src={qualityAssuranceEmployees}
            alt=""
            className={classes.employeesIcon}
          />
          <div className={classes.employees}>{Data.subtitle2}</div>
          <div className={classes.employeesNumber}>{Data.employees}</div>
        </div>{" "} }
        <div className={classes.LineDivider} />
        {isMobile ? (
          <div style={{textAlign:"center"}}>
            <img
              src={certificate1Mob}
              alt=""
              className={classes.certificateMob1}
            />

            <img
              src={certificate2Mob}
              alt=""
              className={classes.certificateMob2}
            />
          </div>
        ) : (
          <div style={{textAlign:"center"}}>
            <img
              src={certificate1Web}
              alt=""
              className={classes.certificateWeb}
            />
            <img
              src={certificate2Web}
              alt=""
              className={classes.certificateWeb}
            />
          </div>
        )}
      </div > */}
    </div>
  )
}
