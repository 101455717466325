import React from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'
import { Data } from '../../Data/LiftMonitoring/ContactUsNow.json'
import contactUsNow from '../../Images/contactUsNow.png'
import contactUsNowMob from '../../Images/contactUsNow.png'
import { useHistory } from 'react-router-dom'
import GetInTouchArrow from '../../Images/button.png'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundImage: isMobile
      ? `url(${contactUsNowMob})`
      : `url(${contactUsNow})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    paddingTop: isMobile ? '9.18vw' : '2.03vw',
    paddingBottom: isMobile ? '10.2vw' : '1.56vw',
    justifyContent: 'left',
    // alignItems: 'center',
    paddingLeft: isMobile ? '3.62vw' : '',
    paddingRight: isMobile ? '4.11vw' : '',
    height: isMobile ? '80vw' : '10.6vw',
  },
  title: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: isMobile ? '7.25vw' : '2.91vw',
    lineHeight: isMobile ? '8.94vw' : '3.85vw',
    color: '#FFFFFF',
    marginBottom: isMobile ? '6.76vw' : '',
  },
  text: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: isMobile ? '4.35vw' : '1.14vw',
    lineHeight: isMobile ? '5.31vw' : '1.66vw',
    color: '#FFFFFF',
    // width: isMobile ? '' : '52%',
  },
  button: {
    width: isMobile ? '29.95vw' : '8vw',
    backgroundColor: '#FA9E23',
    cursor: 'pointer',
    height: isMobile ? '10.1vw' : '2.6vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontSize: isMobile ? '3.38vw' : '0.9vw',
    lineHeight: isMobile ? '4.11vw' : '1.09vw',
    color: '#FFFFFF',
    position: isMobile ? '' : 'absolute',
    left: isMobile ? '' : '-17vw',
    marginTop: isMobile ? '8.45vw' : '',
  },
  arrow: {
    width: isMobile ? '10.144927536231884vw' : '3.541666666666667vw',
    marginLeft: isMobile ? '9.903381642512077vw' : '10vw',
    cursor: 'pointer',
    height: '100%',
  },
})
export default function ContactUsNow() {
  const history = useHistory()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <table
        style={{
          height: '100%',
        }}
      >
        <tbody>
          <tr>
            <td>
              <div
                style={{
                  width: isMobile ? '68.6vw' : '42.3vw',
                  marginLeft: isMobile ? '1.5vw' : '12.34vw',
                }}
              >
                <div className={classes.title}>{Data.title}</div>
                <div className={classes.text}>{Data.content}</div>
              </div>
            </td>
            <td>
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={GetInTouchArrow}
                  className={classes.arrow}
                  alt=''
                  onClick={() => {
                    history.push({
                      pathname: '/contactus',
                      state: 'contact2',
                    })
                  }}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
