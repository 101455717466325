import React, { useRef, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { makeStyles } from '@material-ui/core/styles'
import { Col, Row } from 'react-bootstrap'
import FrankfurtAirport from '../../Images/FrankfurtAirport.png'
import RayaGardenHigh from '../../Images/RayaGardenHigh.png'
import OASISDamansara from '../../Images/OASISDamansara.png'
import HeathrowAirport from '../../Images/HeathrowAirport.png'
import ZaraDubai from '../../Images/ZaraDubai.png'
import HarbinMetro from '../../Images/HarbinMetro.png'
import BrioTowerMakati from '../../Images/BrioTowerMakati.png'
import GuangazhoMetro from '../../Images/GuangazhoMetro.png'
import BukitMerahSingapore from '../../Images/BukitMerahSingapore.png'
import Haishenwai from '../../Images/Haishenwai.png'
import GreenTowers from '../../Images/GreenTowers.png'
import MinistryOfFisheries from '../../Images/MinistryOfFisheries.png'
import RayaGarden from '../../Images/RayaGarden.png'
import KingAbdelazizUniversity from '../../Images/KingAbdelazizUniversity.png'
import DalianMetro1 from '../../Images/DalianMetro-1.png'
import DalianMetro2 from '../../Images/DalianMetro-2.png'
import DalianMetro3 from '../../Images/DalianMetro-3.png'
import BeijingAirport from '../../Images/BeijingAirport.png'
import Moscow from '../../Images/Moscow.png'
import SienaPark from '../../Images/SienaPark.png'
import NovoKLMalaysia from '../../Images/NovoKLMalaysia.png'
import TriconicMalaysia from '../../Images/TriconicMalaysia.png'
import OstiaMalaysia from '../../Images/OstiaMalaysia.png'
import ParlimentKuaweit from '../../Images/ParlimentKuaweit.png'

import { Typography } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import CarouselImage from '../../Images/PP-background2.png'
import LocalIcon from '../../Images/localIcon.png'
import InternationalIcon from '../../Images/internationalIcon.png'
import ArrowBack from '../../Images/backArrow.png'
import { scroller } from 'react-scroll'
//import PrevIcon from '../../Images/prevIcon.png'
//import NextIcon from '../../Images/nextIcon.png'
import './PerformancePortfolio.css'

export default function PerformancePortfolio() {
  const [firstView, setFirstView] = React.useState(true)
  const [firstLayer, setFirstLayer] = React.useState({
    local: true,
    international: false,
  })
  const [localProjects, setLocalProjects] = React.useState({
    sydneyTower: true,
    mlcCentre: false,
    auroraPlace: false,
    crownSydney: false,
    trn: false,
  })
  const internationalProjectsArr = [
    'Frankfurt Airport',
    'Raya Garden High Rise',
    'OASIS Damansara Malaysia',
    'UK Heathrow Airport',
    'Zara Flagship Store at Dubai Festival City',
    'Harbin Metro',
    'Brio Tower Dmci Makati',
    'Guangzhou Metro',
    'Bukit Merah Singapore',
    'Haishenwai',
    'Green Towers, Egypt',
    'The Ministry of Fisheries Wealth, Oman',
    'Raya Garden',
    'King Abdulaziz University, Saudi Arabia',
    'Dalian Metro - 1',
    'Dalian Metro - 2',
    'Dalian Metro - 3',
    'Beijing Airport',
    'Moscow',
    'Siena Park Residences Building Facade',
    'Novo KL Malaysia',
    'Triconic KK Malaysia',
    'Ostia Bangi Malaysia',
    'Parliment of Kuaweit',
  ]
  const localProjectsArr = [
    'Nick Scali',
    'Hurstville Shopping Centre',
    'Westfield Paramatta',
    'Westfield Warringah',
    'TRN House Oran park',
    'Chau Chak Museum',
    'Hurstville Shopping Centre',
    '21 Parraweena road',
    'Primus Hotel',
    'The Exchange',
    'The Adrian',
    'Compass Housing',
    'Clarion',
    'Woli creek Linc',
    'Woli creek Summit',
    'Woli creek Metro',
  ]
  // const localProjectsImgArr=[
  //   sydneyTower,

  // ]
  const internationalProjectsImgArr = [
    FrankfurtAirport,
    RayaGardenHigh,
    OASISDamansara,
    HeathrowAirport,
    ZaraDubai,
    HarbinMetro,
    BrioTowerMakati,
    GuangazhoMetro,
    BukitMerahSingapore,
    Haishenwai,
    GreenTowers,
    MinistryOfFisheries,
    RayaGarden,
    KingAbdelazizUniversity,
    DalianMetro1,
    DalianMetro2,
    DalianMetro3,
    BeijingAirport,
    Moscow,
    SienaPark,
    NovoKLMalaysia,
    TriconicMalaysia,
    OstiaMalaysia,
    ParlimentKuaweit,
  ]
  const [index, setIndex] = useState(0)
  const [index2, setIndex2] = useState(0)
  const useStyles = makeStyles({
    carousel: {
      width: isMobile ? '92vw' : '75vw',
      display: 'flex',
      flexDirection: 'row',
    },
    carouselItemMobile: {
      backgroundImage: `url(${CarouselImage})`,
      height: '88.3vw',
      backgroundSize: 'cover',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '4.5vw',
      width: '92vw',
      // marginLeft: '3vw'
    },
    carouselItem: {
      backgroundImage: `url(${CarouselImage})`,
      width: '75vw',
      height: '26.09375vw',
      backgroundSize: 'cover',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: '1vw',
      // marginLeft: isMobile ? '4vw' : '5vw'
    },
    carouselBar: {
      width: isMobile ? '82vw' : '72.916666666666667vw',
      height: isMobile ? '20.289855072463768vw' : '5.3125vw',
      backgroundColor: '#FA9E23',
      borderRadius: isMobile ? '4.5vw' : '1vw',
      marginTop: isMobile ? '55vw' : '18.7vw',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    carouselSecondBar: {
      width: isMobile ? '80vw' : '72.916666666666667vw',
      height: isMobile ? '20.289855072463768vw' : '5.3125vw',
      backgroundColor: '#FA9E23',
      borderRadius: '1vw',
      marginTop: isMobile ? '55vw' : '18vw',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
      overflow: 'auto',
    },
    internationalIcon: {
      width: isMobile ? '5.797101449275362vw' : '2.1875vw',
      marginRight: isMobile ? '3.072463768115942vw' : '1vw',
      paddingBottom: isMobile ? '' : '0.5vw',
    },
    backButtons: {
      backgroundColor: 'transparent',
      border: '0',
      color: 'white',
      fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
      alignItems: 'center',
      display: 'flex',
      zIndex: '10',
    },
    firstLayerButtons: {
      backgroundColor: 'transparent',
      border: '0',
      color: 'white',
      fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
      marginLeft: isMobile ? '' : '3vw',
      marginRight: isMobile ? '' : '3vw',
      alignItems: 'center',
      display: 'flex',
    },
    secondLayerTitles: {
      width: isMobile ? '' : '25vw',
      backgroundColor: 'transparent',
      border: '0',
      color: 'white',
      fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
      alignItems: 'center',
      display: 'flex',
    },
    secondLayerButtonsClicked: {
      // marginLeft: isMobile ? '0' : '5.260416666666667vw',
      backgroundColor: 'transparent',
      border: '0',
      color: 'white',
      fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
      fontFamily: 'Gothic A1',
      fontWeight: '900',
      textDecoration: 'underline',

      alignItems: 'center',
      clear: 'both',
      display: 'inline-block',
      overflow: 'show',
      whiteSpace: 'nowrap',
      marginLeft: isMobile ? '3vw' : '',
    },
    secondLayerButtonsNot: {
      // marginLeft: isMobile ? '0' : '5.260416666666667vw',
      backgroundColor: 'transparent',
      border: '0',
      color: 'rgba(255, 255, 255, 0.3)',
      fontSize: isMobile ? '4.347826086956522vw' : '1.25vw',
      fontFamily: 'Gothic A1',
      fontWeight: 'normal',
      alignItems: 'center',
      clear: 'both',
      display: 'inline-block',
      overflow: 'show',
      whiteSpace: 'nowrap',
      marginLeft: isMobile ? '3vw' : '',
    },
    title: {
      width: isMobile ? '78.294685990338164vw' : '34vw',
      color: '#FA9E23',
      fontFamily: 'Gothic A1',
      fontSize: isMobile ? '7.246376811594203vw' : '3.020833333333333vw',
      fontWeight: '900',
      // marginLeft: isMobile ? '4vw' : '5vw'
    },
    arrowIcon: {
      width: isMobile ? '3.599vw' : '1.035vw',
    },
    innerProductsCarrousel: {
      overflowX: 'hidden',
    },
  })
  const classes = useStyles()

  const nextIcon = firstLayer.local ? (
    <span
      aria-hidden='true'
      className='carousel-control-next-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        const scrollId = localProjects.crownSydney
          ? 'sydney tower'
          : localProjects.sydneyTower
          ? 'mlc'
          : localProjects.mlcCentre
          ? 'aurora'
          : localProjects.auroraPlace
          ? 'crown sydney'
          : 'trn'
        // document.getElementById(scrollId).scrollIntoView({
        //   behavior: 'smooth',
        //   // block: 'nearest',
        //   inline: 'center',
        // });
        setLocalProjects({
          sydneyTower: localProjects.crownSydney,
          mlcCentre: localProjects.sydneyTower,
          auroraPlace: localProjects.mlcCentre,
          crownSydney: localProjects.auroraPlace,
          trn: localProjects.trn,
        })
      }}
    />
  ) : (
    <span
      aria-hidden='true'
      className='carousel-control-next-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex(
          index < internationalProjectsArr.length - 1 ? index + 1 : index
        )
        document.getElementById(index).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  )
  const prevIcon = firstLayer.local ? (
    <span
      aria-hidden='true'
      className='carousel-control-prev-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        const scrollId = localProjects.mlcCentre
          ? 'sydney tower'
          : localProjects.auroraPlace
          ? 'mlc'
          : localProjects.crownSydney
          ? 'aurora'
          : localProjects.trn
          ? 'crown sydney'
          : 'trn'
        document.getElementById('mlc').scrollIntoView({
          inline: 'center',
          block: 'nearest',
          behavior: 'smooth',
        })
        setLocalProjects({
          sydneyTower: localProjects.mlcCentre,
          mlcCentre: localProjects.auroraPlace,
          auroraPlace: localProjects.crownSydney,
          crownSydney: localProjects.sydneyTower,
          trn: localProjects.trn,
        })
      }}
    />
  ) : (
    <span
      aria-hidden='true'
      className='carousel-control-prev-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex(index > 0 ? index - 1 : index)
        document.getElementById(index).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  )

  const nextIcon2 = firstLayer.local ? (
    <span
      aria-hidden='true'
      className='carousel-control-next-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex2(index2 < localProjectsArr.length - 1 ? index2 + 1 : index2)
        document.getElementById(index2).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  ) : (
    <span
      aria-hidden='true'
      className='carousel-control-next-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex(
          index < internationalProjectsArr.length - 1 ? index + 1 : index
        )
        document.getElementById(index).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  )
  const prevIcon2 = firstLayer.local ? (
    <span
      aria-hidden='true'
      className='carousel-control-prev-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex2(index2 > 0 ? index2 - 1 : index2)
        document.getElementById(index2).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  ) : (
    <span
      aria-hidden='true'
      className='carousel-control-prev-icon'
      style={{ backgroundSize: isMobile ? '70%' : '35%' }}
      onClick={() => {
        setIndex(index > 0 ? index - 1 : index)
        document.getElementById(index).scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        })
      }}
    />
  )
  // eslint-disable-next-line
  const myRef = useRef(null)
  // useEffect(() => {}, [ firstLayer ]);
  //handlers
  // eslint-disable-next-line
  const handleClickFromParent = (event, id) => {
    setFirstView(true)
    setFirstLayer({ local: false, international: false })
  }
  //views
  const LocalButtons = () => {
    return isMobile ? (
      <Row style={{ width: isMobile ? '110vw' : '65vw' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0vw' }}
          >
            <img src={LocalIcon} className={classes.internationalIcon} alt='' />
            {isMobile ? '' : 'LOCAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            overflowX: 'auto',
          }}
          xs={6}
        >
          <button
            className={
              localProjects.sydneyTower
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: true,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: false,
                trn: false,
              })
            }}
            id={'sydney tower'}
          >
            Nick Scali
          </button>
          <button
            className={
              localProjects.mlcCentre
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: true,
                auroraPlace: false,
                crownSydney: false,
                trn: false,
              })
            }}
            id={'mlc'}
          >
            Hurstville Shopping Centre
          </button>
          <button
            className={
              localProjects.auroraPlace
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: true,
                crownSydney: false,
                trn: false,
              })
            }}
            id={'aurora'}
          >
            Westfield Paramatta
          </button>
          <button
            className={
              localProjects.crownSydney
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: true,
                trn: false,
              })
            }}
            id={'crown sydney'}
          >
            Westfield Warringah
          </button>
          <button
            className={
              localProjects.trn
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: false,
                trn: true,
              })
            }}
            id={'trn'}
          >
            TRN House Oran park
          </button>
        </Col>
      </Row>
    ) : (
      <Row style={{ width: isMobile ? '75vw' : '65vw' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0' }}
          >
            <img src={LocalIcon} className={classes.internationalIcon} alt='' />
            {isMobile ? '' : 'LOCAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            marginLeft: '7vw',
          }}
          xs={6}
        >
          <button
            className={
              localProjects.sydneyTower
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: true,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: false,
                trn: false,
              })
            }}
            id={'sydney tower'}
          >
            Nick Scali
          </button>
          <button
            className={
              localProjects.mlcCentre
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: true,
                auroraPlace: false,
                crownSydney: false,
                trn: false,
              })
              // scroller.scrollTo('mlc', {
              //   duration: 800,
              //   delay: 0,
              //   smooth: 'easeInOutQuart',
              // });
            }}
            id={'mlc'}
          >
            Hurstville Shopping Centre
          </button>
          <button
            className={
              localProjects.auroraPlace
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: true,
                crownSydney: false,
                trn: false,
              })
            }}
            id={'aurora'}
          >
            Westfield Paramatta
          </button>
          <button
            className={
              localProjects.crownSydney
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: true,
                trn: false,
              })
            }}
            id={'crown sydney'}
          >
            Westfield Warringah
          </button>
          <button
            className={
              localProjects.crownSydney
                ? classes.secondLayerButtonsClicked
                : classes.secondLayerButtonsNot
            }
            onClick={() => {
              setLocalProjects({
                sydneyTower: false,
                mlcCentre: false,
                auroraPlace: false,
                crownSydney: false,
                trn: true,
              })
            }}
            id={'trn'}
          >
            TRN House Oran park
          </button>
        </Col>
      </Row>
    )
  }
  const LocalButtons2 = () => {
    return isMobile ? (
      <Row style={{ width: isMobile ? '75vw' : '65vw', overflowX: 'auto' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0vw' }}
          >
            <img src={LocalIcon} className={classes.internationalIcon} alt='' />
            {isMobile ? '' : 'LOCAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            marginLeft: isMobile ? '0vw' : '5vw',
          }}
          xs={6}
        >
          {localProjectsArr.map((one, i) => {
            return (
              <button
                className={
                  i == index2
                    ? classes.secondLayerButtonsClicked
                    : classes.secondLayerButtonsNot
                }
                onClick={() => {
                  setIndex2(i)
                  // document.getElementById(index).scrollIntoView({
                  //   behavior: 'smooth',
                  //   block: 'nearest',
                  //   inline: 'center',
                  // });
                }}
                id={i}
              >
                {localProjectsArr[i]}
              </button>
            )
          })}
        </Col>
      </Row>
    ) : (
      <Row style={{ width: isMobile ? '75vw' : '65vw' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0' }}
          >
            <img src={LocalIcon} className={classes.internationalIcon} alt='' />
            {isMobile ? '' : 'LOCAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            marginLeft: '15vw',
          }}
          xs={6}
        >
          {localProjectsArr.map((one, i) => {
            return (
              <button
                className={
                  i == index2
                    ? classes.secondLayerButtonsClicked
                    : classes.secondLayerButtonsNot
                }
                onClick={() => {
                  setIndex2(i)
                  // document.getElementById(index).scrollIntoView({
                  //   behavior: 'smooth',
                  //   block: 'nearest',
                  //   inline: 'center',
                  // });
                }}
                id={i}
              >
                {localProjectsArr[i]}
              </button>
            )
          })}
        </Col>
      </Row>
    )
  }
  const InternationalButtons = () => {
    return isMobile ? (
      <Row style={{ width: isMobile ? '75vw' : '65vw', overflowX: 'auto' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0vw' }}
          >
            <img
              src={InternationalIcon}
              className={classes.internationalIcon}
              alt=''
            />
            {isMobile ? '' : 'INTERNATIONAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            marginLeft: isMobile ? '0vw' : '5vw',
          }}
          xs={6}
        >
          {internationalProjectsArr.map((one, i) => {
            return (
              <button
                className={
                  i == index
                    ? classes.secondLayerButtonsClicked
                    : classes.secondLayerButtonsNot
                }
                onClick={() => {
                  setIndex(i)
                  // document.getElementById(index).scrollIntoView({
                  //   behavior: 'smooth',
                  //   block: 'nearest',
                  //   inline: 'center',
                  // });
                }}
                id={i}
              >
                {internationalProjectsArr[i]}
              </button>
            )
          })}
        </Col>
      </Row>
    ) : (
      <Row style={{ width: isMobile ? '75vw' : '65vw' }}>
        <Col xs={2}>
          <button
            className={classes.secondLayerTitles}
            style={{ marginLeft: '0' }}
          >
            <img
              src={InternationalIcon}
              className={classes.internationalIcon}
              alt=''
            />
            {isMobile ? '' : 'INTERNATIONAL PROJECTS'}
          </button>
        </Col>
        <Col
          style={{
            display: 'flex',
            marginLeft: '15vw',
          }}
          xs={6}
        >
          {internationalProjectsArr.map((one, i) => {
            return (
              <button
                className={
                  i == index
                    ? classes.secondLayerButtonsClicked
                    : classes.secondLayerButtonsNot
                }
                onClick={() => {
                  setIndex(i)
                  // document.getElementById(index).scrollIntoView({
                  //   behavior: 'smooth',
                  //   block: 'nearest',
                  //   inline: 'center',
                  // });
                }}
                id={i}
              >
                {internationalProjectsArr[i]}
              </button>
            )
          })}
        </Col>
      </Row>
    )
  }

  const FirstLayerButtons = () => {
    return (
      <div className={classes.carouselBar}>
        <button
          className={classes.firstLayerButtons}
          onClick={() => {
            setFirstView(false)
            setFirstLayer({ local: true, international: false })
          }}
        >
          <img src={LocalIcon} className={classes.internationalIcon} alt='' />
          {isMobile ? 'LOCAL' : 'LOCAL PROJECTS'}
        </button>
        <button
          className={classes.firstLayerButtons}
          onClick={() => {
            setFirstView(false)
            setFirstLayer({ local: false, international: true })
          }}
        >
          <img
            src={InternationalIcon}
            className={classes.internationalIcon}
            alt=''
          />
          {isMobile ? 'INTERNATIONAL' : 'INTERNATIONAL PROJECTS'}
        </button>
      </div>
    )
  }

  return (
    <div>
      <Typography className={classes.title}>
        OUR PERFORMANCE HISTORY PORTFOLIO
      </Typography>
      <Carousel
        className={classes.carousel}
        indicators={false}
        controls={!firstView}
        nextIcon={nextIcon2}
        prevIcon={prevIcon2}
      >
        <Carousel.Item>
          <div
            className={
              !isMobile ? classes.carouselItem : classes.carouselItemMobile
            }
          >
            {firstView ? (
              <FirstLayerButtons />
            ) : (
              <div className={classes.carouselSecondBar}>
                <button
                  onClick={() => {
                    console.log('CLICKED')
                    setFirstView(true)
                    setFirstLayer({ local: false, international: false })
                  }}
                  className={classes.backButtons}
                  style={{
                    width: '4vw',
                    paddingRight: isMobile ? '4vw' : '1vw',
                    paddingLeft: '2.65625vw',
                  }}
                >
                  <img className={classes.arrowIcon} src={ArrowBack} alt='' />
                </button>
                <div className={classes.innerProductsCarrousel}>
                  {firstLayer.local ? (
                    <LocalButtons2 />
                  ) : (
                    <InternationalButtons />
                  )}
                </div>
              </div>
            )}
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}
