import React, { useState, useEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useLocation, useHistory } from 'react-router-dom'
import '../../StyleSheets/NavCarousel.css'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile, isTablet } from 'react-device-detect'

import homeLogo from '../../Images/homeLogo.png'
import aboutUsLogo from '../../Images/aboutUsLogo.png'
import productsLogo from '../../Images/productsLogo.png'
import upgradesLogo from '../../Images/upgradesLogo.png'
import servicesLogo from '../../Images/servicesLogo.png'
import contactUsLogo from '../../Images/contactUsLogo.png'
import liftMonitoringLogo from '../../Images/monitoringLogo.png'

const useStyles = makeStyles((theme) => ({
  navCarouselFont: {
    fontFamily: 'Gothic A1',
    color: '#ffffff',
    fontSize: isTablet ? '4vw' : isMobile ? '4vw' : '1.2vw',
    cursor: 'pointer',
    visibility: 'visible',
    fontWeight: 'bolder',
    opacity: 1,
  },
  navCarouselImage: {
    fontFamily: 'Gothic A1',
    width: isMobile ? '13.2vw' : '5.5vw',
    cursor: 'pointer',
  },
  divImage: {
    minHeight: isMobile ? '17.7vw' : '6.5vw',
    verticalAlign: 'top',
    textAlign: 'center',
  },
  isNotNavigating: {
    fontWeight: 'bolder',
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 1s, opacity 0.5s linear',
    fontFamily: 'Gothic A1',
    color: '#ffffff',
    fontSize: isTablet ? '4vw' : isMobile ? '4vw' : '1.2vw',
    cursor: 'pointer',
  },
}))

export default function NavCarousel(props) {
  const { pathname } = useLocation()
  const history = useHistory()

  // eslint-disable-next-line
  const p = props.pageMain
  const [activeTab, setActiveTab] = useState(0)
  const [currentActiveTab, setCurrentActiveTab] = useState(
    pathname.includes('/home')
      ? 0
      : pathname.includes('/products')
      ? 1
      : pathname.includes('/services')
      ? 2
      : pathname.includes('/liftmonitoring')
      ? 3
      : pathname.includes('/upgrade')
      ? 4
      : pathname.includes('/aboutus')
      ? 5
      : 6
  )

  const [isNavigating, setIsNavigating] = useState(false)
  const classes = useStyles()

  const setCurrentActiveTab1 = (index) => {
    setCurrentActiveTab(index)
    props.setPage({
      home: index === 0,
      products: index === 1,
      services: index === 2,
      liftmonitoring: index == 3,
      upgrade: index === 4,
      aboutus: index === 5,
      contactus: index === 6,
    })

    switch (index) {
      case 0:
        history.push('/home')
        break
      case 1:
        history.push('/products')
        break
      case 2:
        history.push('/services')
        break
      case 3:
      history.push('/liftmonitoring')
      break
      case 4:
        history.push('/upgrade')
        break
      case 5:
        history.push('/aboutus')
        break
      case 6:
        history.push('/contactus')
        break
      default:
        history.push('/home')
    }
  }

  useEffect(() => {
    if (pathname.includes('/home')) {
      setCurrentActiveTab(0)
    } else if (pathname.includes('/products')) {
      setCurrentActiveTab(1)
    } else if (pathname.includes('/services')) {
      setCurrentActiveTab(2)
    } else if (pathname.includes('/liftmonitoring')) {
      setCurrentActiveTab(3)
    } else if (pathname.includes('/upgrade')) {
      setCurrentActiveTab(4)
    } else if (pathname.includes('/aboutus')) {
      setCurrentActiveTab(5)
    } else if (pathname.includes('/contactus')) {
      setCurrentActiveTab(6)
    } else {
      setCurrentActiveTab(0)
    }
  }, [pathname])

  return (
    <div>
      <div
        className={classes.divImage}
        onClick={() =>
          isNavigating ? setIsNavigating(false) : setIsNavigating(true)
        }
      >
        <img
          src={
            currentActiveTab === 0
              ? homeLogo
              : currentActiveTab === 1
              ? productsLogo
              : currentActiveTab === 2
              ? servicesLogo
              : currentActiveTab === 3
              ? liftMonitoringLogo
              : currentActiveTab === 4
              ? upgradesLogo
              : currentActiveTab === 5
              ? aboutUsLogo
              : contactUsLogo
          }
          alt=''
          className={classes.navCarouselImage}
        />
      </div>
      <div>
        <Splide
          options={{
            type: 'slide',
            perPage: 6,
            perMove: 1,
            focus: 'center',
            gap: '28px',
            arrows: false,
            pagination: false,
            drag: true,
            autoWidth: true,
            trimSpace: false,
            start: currentActiveTab,
            swipeDistanceThreshold: 10,
          }}
          onClick={(splide) => {
            if (!isNavigating) {
              setIsNavigating(true)
            } else {
              splide.go(activeTab)
              setIsNavigating(false)
            }
          }}
          onMove={(splide) => {
            setCurrentActiveTab1(splide.index)
          }}
          onMoved={() => setIsNavigating(false)}
        >
          <SplideSlide
            className={
              isNavigating || currentActiveTab === 0
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(0)}>Home</div>
          </SplideSlide>
          <SplideSlide
            className={
              isNavigating || currentActiveTab === 1
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(1)}>Products</div>
          </SplideSlide>
          <SplideSlide
            className={
              isNavigating || currentActiveTab === 2
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(2)}>Services</div>
          </SplideSlide>
          <SplideSlide
            className={
              isNavigating || currentActiveTab === 3
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(3)}>Monitoring</div>
          </SplideSlide>
          <SplideSlide
            className={
              isNavigating || currentActiveTab === 4
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(4)}>Upgrade</div>
          </SplideSlide>

          <SplideSlide
            className={
              isNavigating || currentActiveTab === 5
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(5)}>About Us</div>
          </SplideSlide>

          <SplideSlide
            className={
              isNavigating || currentActiveTab === 6
                ? classes.navCarouselFont
                : classes.isNotNavigating
            }
          >
            <div onMouseOver={() => setActiveTab(6)}>Contact</div>
          </SplideSlide>

         
        </Splide>
      </div>
    </div>
  )
}
