import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import HomeliftImg from '../../Images/residentialElevators-homelift.png';
import HomeliftImgMobile from '../../Images/residentialElevators-homeliftMobile.png';
import VacuumImg from '../../Images/residentialElevators-vacuum.png';
import VacuumImgMobile from '../../Images/residentialElevators-vacuumMobile.png';
import PanoramicImg from '../../Images/commercialElevators-Panoramic.png';
import PanoramicImgMobile from '../../Images/commercialElevators-PanoramicMobile.png';
// import centerImg from '../../Images/residentialElevators-centralImg.png'
import residentialHome from '../../Images/residential_home.png';
import residentialVacuum from '../../Images/residential_vacuum.png';
import residentialPanoramic from '../../Images/residential_panoramic.png';

//import centerImgMobile from '../../Images/residentialElevators-centralImgMobile.png'
import data from '../../Data/Products/ResidentialElevatorsCarrousel.json';
import { carrouselStyles } from './CarrouselStyles';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

const useStyles = makeStyles(carrouselStyles);

export default function Carrousel() {
  const styles = useStyles();
  const [selected, setSelected] = useState('homelift');
  const history = useHistory();
  const selectedData =
    selected === 'homelift'
      ? data.Homelift
      : selected === 'panoramic'
      ? data.Panoramic
      : data.Vacuum;

  return (
    <div style={{ margin: '4vw' }} className={styles.container}>
      <div className={styles.carrousel}>
        <div className={styles.optionDiv}>
          {selected === 'homelift' ? (
            <img
              alt=''
              src={isMobile ? HomeliftImgMobile : HomeliftImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('homelift')}
            >
              home lift
            </p>
          )}
        </div>
        <div className={styles.optionDiv}>
          {selected === 'panoramic' ? (
            <img
              alt=''
              src={isMobile ? PanoramicImgMobile : PanoramicImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('panoramic')}
            >
              panoramic
            </p>
          )}
        </div>
        <div className={styles.optionDiv}>
          {selected === 'vacuum' ? (
            <img
              alt=''
              src={isMobile ? VacuumImgMobile : VacuumImg}
              className={styles.circleCarrousel}
            />
          ) : (
            <p
              className={styles.carrouselText}
              onClick={() => setSelected('vacuum')}
            >
              vacuum
            </p>
          )}
        </div>
      </div>
      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),
        url(${
          selected === 'vacuum'
            ? residentialVacuum
            : selected === 'panoramic'
            ? residentialPanoramic
            : residentialHome
        })`,
          }}
        >
          <span className={styles.imgText}>Residential Elevators</span>
        </div>
      ) : (
        <img
          src={
            selected === 'vacuum'
              ? residentialVacuum
              : selected === 'panoramic'
              ? residentialPanoramic
              : residentialHome
          }
          alt=''
          className={styles.centerImg}
        />
      )}
      <div className={styles.textDiv}>
        {isMobile ? null : (
          <h2 className={styles.mainTitle}>Residential Elevators</h2>
        )}
        <h4 className={styles.subTitle}>{selectedData.title}</h4>
        <pre className={styles.content}>{parse(selectedData.content)}</pre>
        <button
          className={styles.btn}
          onClick={() => {
            history.push({ pathname: '/contactus', state: 'contact2' });
          }}
        >
          ENQUIRE
        </button>
      </div>
    </div>
  );
}
