import React, { useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap';

import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

//first col
import grindley from '../../Images/grindley.png'
import prime from '../../Images/prime-projects.png'
import besix from '../../Images/besix-watpac.png'
import patterson from '../../Images/patterson.png'
//second col
import ultra from '../../Images/ultra.png'
import construction from '../../Images/construction-profile.png'
import primeconst from '../../Images/prime-construction.png'
import growth from '../../Images/growthbuilt.png'
//third col
import buildex from '../../Images/buildex.png'
import beaini from '../../Images/beaini.png'
import north from '../../Images/north.png'
import pyramids from '../../Images/pyramids-builders.png'
//fourth col
import richard from  '../../Images/richardcrookes.png'
import fdc from '../../Images/FDC.png'
import jcg from '../../Images/JCG.png'
import keks from '../../Images/keksprojects.png'
//fifth col
import sw from '../../Images/SW.png'
import mars from '../../Images/mars.png'
import jh from '../../Images/JH.png'

export default function Clients() {

    const useStyles = makeStyles({
        root:{ 
            paddingLeft: isMobile ? "4vw" : "12.5vw",
            paddingRight: isMobile ? "4vw" : "12.5vw",
        },
        card: {
            width: isMobile ? '92vw' : '75vw',
            display: 'flex',
            flexDirection: 'col',
            background: '#FFFFFF',
            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
            borderRadius: '1vw',
            marginTop:'2.45vw',
            marginBottom: '7.55vw',
            paddingLeft: isMobile? '10vw':'2vw',
        },
        title: {
            width: isMobile ? '78.294685990338164vw' : '34vw',
            color: '#FA9E23',
            fontFamily: 'Gothic A1',
            fontSize: isMobile ? '7.246376811594203vw' : '3.020833333333333vw',
            fontWeight: '900',
        },
        logo:{
            width: isMobile ? '18.735vw': '8.415vw',
        },
    })
    const classes = useStyles();

    return (
        <div style={{paddingLeft: isMobile ? "4vw" : "12.5vw",}}>
            {console.log(isMobile)}
            <Typography className={classes.title}>
                Clients
            </Typography>
            {isMobile? (
                <div className={classes.card}>
                <Col style={{paddingTop: isMobile? '6.7vw':'2.915vw', paddingBottom: isMobile? '6.9vw':'3vw'}}>
                    <div style={{paddingBottom: isMobile? '8.4755vw': '3.685vw'}}>
                        <img className={classes.logo} src={grindley} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '8.4755vw': '3.685vw'}}>
                        <img className={classes.logo} src={prime} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '8.4755vw': '3.685vw'}}>
                        <img style={{width: isMobile? '20.24vw':'8.8vw'}} src={besix} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '8.4755vw': '3.685vw'}}>
                        <img className={classes.logo} src={patterson} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={buildex} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={beaini} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img style={{width: isMobile? '23.9vw':'10.395vw'}} src={north} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={pyramids} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img style={{width: isMobile? '15.43vw':'6.71vw'}} src={sw} alt=''/>
                    </div>
                    <div >
                        <img style={{width: isMobile ? '12.93vw' :'5.61vw'}} src={jh} alt=''/>
                    </div>
                </Col>
                <Col style={{paddingTop: isMobile? '6.7vw':'2.915vw', paddingBottom: isMobile? '7.88vw':'3.4265vw'}}>
                    <div style={{paddingBottom: isMobile? '10vw':'4.345vw'}}>
                        <img className={classes.logo} src={ultra} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '10vw':'4.345vw'}}>
                        <img className={classes.logo} src={construction} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '10vw':'4.345vw'}}>
                        <img className={classes.logo} src={primeconst} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '10vw':'4.345vw'}}>
                        <img style={{width: isMobile? '20.6195vw':'8.965vw'}} src={growth} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img style={{width:isMobile? '24vw':'10.45vw'}} src={richard} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={fdc} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={jcg} alt=''/>
                    </div>
                    <div style={{paddingBottom: isMobile? '9.2vw':'4vw'}}>
                        <img className={classes.logo} src={keks} alt=''/>
                    </div>
                    <div >
                        <img style={{width: isMobile ? '12.93vw' :'5.61vw'}} src={mars} alt=''/>
                    </div>
                </Col>
            </div>
            ):(
                <div className={classes.card}>
                <Col style={{paddingTop: '2.915vw', paddingBottom: '3vw', paddingRight: '4vw'}}>
                    <div style={{paddingBottom:  '3.685vw'}}>
                        <img className={classes.logo} src={grindley} alt=''/>
                    </div>
                    <div style={{paddingBottom: '3.685vw'}}>
                        <img className={classes.logo} src={prime} alt=''/>
                    </div>
                    <div style={{paddingBottom: '3.685vw'}}>
                        <img style={{width: '8.8vw'}} src={besix} alt=''/>
                    </div>
                    <div>
                        <img className={classes.logo} src={patterson} alt=''/>
                    </div>
                </Col>
                <Col style={{paddingTop: '2.915vw', paddingBottom: '3.4265vw', paddingRight: '4vw'}}>
                    <div style={{paddingBottom: '4.345vw'}}>
                        <img className={classes.logo} src={ultra} alt=''/>
                    </div>
                    <div style={{paddingBottom: '4.345vw'}}>
                        <img className={classes.logo} src={construction} alt=''/>
                    </div>
                    <div style={{paddingBottom: '4.345vw'}}>
                        <img className={classes.logo} src={primeconst} alt=''/>
                    </div>
                    <div>
                        <img style={{width: '8.965vw'}} src={growth} alt=''/>
                    </div>
                </Col>
                <Col style={{paddingTop: '2.915vw', paddingRight: '4vw'}}>
                    <div style={{paddingBottom:'4vw'}}>
                        <img className={classes.logo} src={buildex} alt=''/>
                    </div>
                    <div style={{paddingBottom:'4vw'}}>
                        <img className={classes.logo} src={beaini} alt=''/>
                    </div>
                    <div style={{paddingBottom:'4vw'}}>
                        <img style={{width: '10.395vw'}} src={north} alt=''/>
                    </div>
                    <div style={{paddingBottom: '4vw'}}>
                        <img className={classes.logo} src={pyramids} alt=''/>
                    </div>
                </Col>
                <Col style={{paddingTop: '2.915vw', paddingRight: '4vw'}}>
                    <div style={{paddingBottom: '4vw'}}>
                        <img style={{width:'10.45vw'}} src={richard} alt=''/>
                    </div>
                    <div style={{paddingBottom:'4vw'}}>
                        <img className={classes.logo} src={fdc} alt=''/>
                    </div>
                    <div style={{paddingBottom: '4vw'}}>
                        <img className={classes.logo} src={jcg} alt=''/>
                    </div>
                    <div style={{paddingBottom: '4vw'}}>
                        <img className={classes.logo} src={keks} alt=''/>
                    </div>
                </Col>
                <Col style={{paddingTop: '2.915vw', paddingRight: '0vw'}}>
                    <div style={{paddingBottom:'4vw'}}>
                        <img style={{width: '6.71vw'}} src={sw} alt=''/>
                    </div>
                    <div style={{paddingBottom:'4vw'}}>
                        <img style={{width: '5.61vw'}} src={mars} alt=''/>
                    </div>
                    <div >
                        <img style={{width: '5.61vw'}} src={jh} alt=''/>
                    </div>
                </Col>
            </div>
            )}
        </div>
    )
}