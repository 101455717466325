import React from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

import askAbout from '../../Images/askAbout.png'
import askAboutM from '../../Images/askAboutM.png'
import RightArrow from '../../Images/button.png'

import data from '../../Data/Upgrade/AskAboutD.json'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    marginTop: '0vw',
    marginBottom: '0vw',
    color: 'white',
    fontFamily: 'Gothic A1',
    fontWeight: 900,
    fontSize: isMobile ? '6.757vw' : '3vw',
  },
  content: {
    marginTop: isMobile ? '8vw' : '0vw',
    marginBottom: '0vw',
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Gothic A1',

    fontSize: isMobile ? '4vw' : '1.2vw',
  },
  main: {
    position: 'relative',
    paddingTop: isMobile ? '9vw' : '2vw',
    textAlign: 'left',
    width: '100vw',
    height: isMobile ? '71vw' : '10.9vw',
    backgroundImage: `URL(${isMobile ? askAboutM : askAbout})`,
    backgroundSize: 'contain',
  },
  text: {
    marginLeft: isMobile ? '5vw' : '12.5vw',
    width: isMobile ? '60vw' : '45vw',
  },
  arrow: {
    position: 'absolute',
    top: isMobile ? '37.5vw' : '3.5vw',
    right: isMobile ? '2vw' : '34vw',
    width: isMobile ? '11vw' : '3.5vw',
    cursor: 'pointer',
  },
})

export default function AskAbout() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.main}>
      <div className={classes.text}>
        <p className={classes.title}>{data.title}</p>
        <p className={classes.content}>{data.content}</p>
        <img
          src={RightArrow}
          className={classes.arrow}
          alt=''
          onClick={() => {
            history.push({
              pathname: '/contactus',
              state: 'contact2',
            })
          }}
        />
      </div>
    </div>
  )
}
