import React from "react";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import data from "../../Data/ContactUs/ContactInfo.json";
import locationImgY from "../../Images/locationImg.png";
import messageImgY from "../../Images/messageImg.png";
import phoneImgY from "../../Images/phoneImg.png";
import locationImgW from "../../Images/locationImgWhite.png";
import messageImgW from "../../Images/messageImgWhite.png";
import phoneImgW from "../../Images/phoneImgWhite.png";

export default function Lifts(props) {
  const dark = props.dark;
  const useStyles = makeStyles({
    root: {
      marginLeft: isMobile ? "0vw" : "12.5vw",
      background: dark ? "#4B4B4B" : "#FFFFFF",
      paddingTop: isMobile ? "9.8vw" : "2.5vw",
      fontFamily: "Gothic A1",
      textAlign: "left",
      boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
      borderRadius: isMobile ? "5.32vw 5.32vw 0vw 0vw" : "1vw 0vw 0vw 1vw",
      width: isMobile ? "92vw" : "37.5vw",
      height: isMobile ? "192.5vw" : "46vw",
      transition: "background 1s ease",
      WebkitTransition: "background 1s ease",
      MozTransition: "background 1s ease",
      lineHeight: isMobile ? "6.5vw" : "2vw",
    },
    title: {
      paddingTop: isMobile ? "" : "1vw",
      paddingLeft: isMobile ? "4vw" : "4.25vw",
      paddingBottom: isMobile ? "" : "1vw",
      fontSize: isMobile ? "7vw" : "3vw",
      fontWeight: "900",
      color: dark ? "#FFF" : "#FA9E23",
      fontFamily: "Gothic A1",
    },
    smallTitle: {
      paddingBottom: isMobile ? "1vw" : "2vw",
      paddingLeft: isMobile ? "4vw" : "4.25vw",
      fontSize: isMobile ? "5vw" : "1.6vw",
      fontWeight: "bold",
      color: dark ? "#FFF" : "#FA9E23",
      marginTop: isMobile ? "6vw" : "",
    },
    paragraph: {
      paddingLeft: isMobile ? "4vw" : "4.25vw",
      marginBottom: isMobile ? "" : "2vw",
      width: isMobile ? "90vw" : "32vw",
      fontSize: isMobile ? "4.5vw" : "1.2vw",
      color: dark ? "#FFF" : "#4B4B4B",
    },
    text: {
      paddingLeft: isMobile ? "8.8vw" : "1vw",
      width: isMobile ? "55vw" : "16vw",
      fontSize: isMobile ? "4.5vw" : "1.2vw",
      color: dark ? "#FFF" : "#4B4B4B",
      paddingTop: isMobile ? "4vw" : "",
    },
    locImg: {
      marginLeft: isMobile ? "4vw" : "4.25vw",
      width: isMobile ? "4.25vw" : "1.1vw",
      paddingBottom: "0.5vw",
    },
    phoneImg: {
      marginLeft: isMobile ? "4vw" : "4.25vw",
      width: isMobile ? "5.26vw" : "1.37vw",
      paddingBottom: "0.5vw",
    },
    msgImg: {
      marginLeft: isMobile ? "4vw" : "4.25vw",
      width: isMobile ? "6.7vw" : "1.75vw",
      paddingBottom: "0.5vw",
    },
  });

  const styles = useStyles();
  const locationImg = dark ? locationImgW : locationImgY;
  const phoneImg = dark ? phoneImgW : phoneImgY;
  const messageImg = dark ? messageImgW : messageImgY;
  return (
    <div className={styles.root}>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.paragraph}>
        {" "}
        <br />
        {data.paragraph}
      </div>

      <div className={styles.smallTitle}>{data.headOfficeTitle}</div>
      <table>
        <tbody>
          <tr
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("https://goo.gl/maps/ytBtU28ph1q2ZdP78 ")
            }
          >
            <td>
              <img src={locationImg} className={styles.locImg} alt=""></img>
            </td>
            <td className={styles.text}>{data.headOfficeAddress1}</td>
          </tr>
          <tr>
            <td>
              <img src={phoneImg} className={styles.phoneImg} alt=""></img>
            </td>
            <td className={styles.text}>{data.headOfficePhone}</td>
          </tr>
          <tr>
            <td>
              <img src={messageImg} className={styles.msgImg} alt=""></img>
            </td>
            <td className={styles.text}>{data.headOfficeMail}</td>
          </tr>
        </tbody>
      </table>
      <p></p>
      <div className={styles.smallTitle} style={{   marginTop:isMobile? "8vw": "2vw"   }}>
        
        
        {data.BLATitle}
      
      
      </div>
      <table>
        <tbody>
          <tr>
            <td>
              <img src={phoneImg} className={styles.phoneImg} alt=""></img>
            </td>
            <td className={styles.text}>{data.BLAPhone}</td>
          </tr>
          <tr>
            <td>
              <img src={messageImg} className={styles.msgImg} alt=""></img>
            </td>
            <td className={styles.text}>{data.headOfficeMail}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
