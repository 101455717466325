import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import BLATravelatorsImg from '../../Images/Travelators.png';
import BLATravelatorsImgMobile from '../../Images/TravelatorsMobile.png';
import BLATravelators from '../../Images/BLATravelators.png'
import data from '../../Data/Products/TravelatorsCarrousel.json';
import { carrouselStyles } from './CarrouselStyles';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

const useStyles = makeStyles(carrouselStyles);

export default function Carrousel() {
  const styles = useStyles();
  const [selected, setSelected] = useState('bla travelators');
  const history = useHistory();
  const selectedData = data.travelators

  return (
    <div style={{ margin: '4vw' }} className={styles.container}>
      <div className={styles.carrousel}>
        <div className={styles.optionDiv}>
            <img
              alt=''
              src={isMobile ? BLATravelatorsImgMobile : BLATravelatorsImg}
              className={styles.circleCarrousel}
            />
        </div>
      </div>
      {isMobile ? (
        <div
          className={styles.centerImgDiv}
          style={{
            backgroundImage:
              isMobile &&
              `linear-gradient(to bottom, rgba(75, 75, 75, 0), #4B4B4B),url(${BLATravelators})`,
          }}
        >
          <span className={styles.imgText}>Travelators</span>
        </div>
      ) : (
        <img
          src={BLATravelators}
          alt=''
          className={styles.centerImg}
        />
      )}
      <div className={styles.textDiv}>
        {isMobile ? null : (
          <h2 className={styles.mainTitle}>Travelators</h2>
        )}
        <h4 className={styles.subTitle}>{selectedData.title}</h4>
        <pre className={styles.content}>{parse(selectedData.content)}</pre>
        <button
          className={styles.btn}
          onClick={() => {
            history.push({ pathname: '/contactus', state: 'contact2' });
          }}
        >
          ENQUIRE
        </button>
      </div>
    </div>
  );
}
