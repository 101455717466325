import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import { Data } from '../../Data/Home/GetTo';
import GetToKnowImage from '../../Images/GetToKnow.png';

const useStyles = makeStyles((theme) => ({
  general: {
    paddingTop: isMobile ? '10vw' : '4vw',
    paddingBottom: isMobile ? '5vw' : '4vw',
    width: isMobile ? '92vw' : '75vw',
  },
  image: {
    height: isMobile ? 'unset' : '29vw',
    width: isMobile ? '100%' : 'unset',
    marginBottom: isMobile ? '9vw' : '0px',
  },
  text: {
    verticalAlign: 'top',
    textAlign: 'Left',
    minHeight: isMobile ? 'unset' : '29vw',
    padding: isMobile ? '0' : '',
  },
  title: {
    marginTop: isMobile ? '' : '-0.65vw',
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? 'max(7vw, 20px)' : 'max(3vw, 35px)',
    fontWeight: '900',
    color: '#FA9E23',
    marginBottom: isMobile ? '4vw' : '1vw',
  },
  body: {
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? 'max(4vw, 18px)' : 'max(1.2vw, 14px)',
    marginBottom: isMobile ? '4vw' : '1vw',
  },
  clickableBody: {
    fontFamily: 'Gothic A1',
    fontSize: isMobile ? 'max(4vw, 18px)' : 'max(1vw, 15px)',
    textDecoration: 'underline',
    color: '#FA9E23',
    cursor: 'pointer',
  },
  button: {
    marginTop: isMobile ? '7vw' : '0px',
    width: isMobile ? 'max(30vw, 120px)' : '8vw',
    height: isMobile ? 'max(10vw, 40px)' : '2.7vw',
    backgroundColor: '#FA9E23',
    borderRadius: isMobile ? '0px 2.5vw 0px 2.5vw' : '0px 0.5vw 0px 0.5vw',
    fontSize: isMobile ? 'max(3vw, 13px)' : '0.9vw',
    fontWeight: '500',
    lineHeight: isMobile ? 'max(10vw, 40px)' : 'max(2.4vw, 35px)',
    cursor: 'pointer',
    color: '#ffffff',
    position: !isMobile ? 'absolute' : 'relative',
    bottom: 0,
    textAlign: 'center',
  },
  paragraph: { marginBottom: isMobile ? '4vw' : '1vw' },
  col: {
    padding: '0 !important',
  },
}));

export default function GetToKnow() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.general}>
      <Row style={{ margin: 'auto' }}>
        <Col xs={12} lg={4} className={classes.col}>
          <img src={GetToKnowImage} alt='' className={classes.image} />
        </Col>
        <Col xs={12} lg={8} className={classes.text}>
          <div className={classes.title}>{Data.title}</div>
          <div className={classes.paragraph}>
            <span className={classes.body}>{Data.bodyFirstParagraph}</span>
            <span className={classes.body}>{Data.bodyClickablePart}</span>

            {/* <span
              className={classes.clickableBody}
              onClick={() => {
                document
                  .getElementById('divElem')
                  .scrollIntoView({ behavior: 'smooth', block: 'center' });

                // window.scrollTo({ top: "100%", behavior: "smooth" });
              }}
            > */}
            {/* Add here onClick function to go to Testimonials. 
                Also, I changed the color to indicate clickable section */}
            {/* {Data.bodyClickablePart}
            </span> */}
          </div>
          <div className={classes.body}>{Data.bodySecondParagraph}</div>
          <div className={classes.body}>
            {/* {' '}
            <span
              style={{
                cursor: 'pointer',
                color: '#FA9E23',
                textDecoration: 'underline',
              }}
              onClick={() => {
                history.push('/products');
                window.scrollTo(0, 0);
              }}
            >
              Products
            </span>{' '} */}
            Our Products are not just affordable, but they are of outstanding
            quality as well. We pride ourselves on our durability and
            reliability. Our lift and escalator systems were designed to suit
            all residential, commercial and high-rise projects. You can rely on
            Brilliant to provide you with 24/7 support as well.
            {/* <span
              style={{
                cursor: 'pointer',
                color: '#FA9E23',
                textDecoration: 'underline',
              }}
              onClick={() => {
                history.push('/services');
                window.scrollTo(0, 0);
              }}
            >
              Support
            </span>{' '} */}
          </div>
          <div
            className={classes.button}
            onClick={() => {
              history.push('/aboutus');
              window.scrollTo(0, 0);
            }}
          >
            {Data.button}
          </div>
        </Col>
      </Row>
    </div>
  );
}
