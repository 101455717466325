import React from 'react'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  stayAheadCardDiv: {
    width: isMobile ? "90vw" : "14.167vw",
    height: isMobile ? "41vw" : "13vw",
    backgroundColor: "#4B4B4B",
    borderRadius: isMobile ? "4.5vw" : "1.04vw",
    position: "relative",
    // marginRight: '1vw',
  },
  textDiv: {
    width: isMobile ? "35.6vw" : "12.18vw",
    margin: "0 auto",
    textAlign: "center",
    position: "relative",
    top: isMobile ? "7.2vw" : "2.4vw",
  },
  text: {
    whiteSpace: "pre-wrap",
    color: "#fff",
    fontFamily: "Gothic A1",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: isMobile ? "4.5vw" : "1.6vw",
  },
  cardImg: {
    width: "22vw",
    position: "absolute",
    top: "75%",
    left: "37.5%",
  },
});

export default function StayAheadCard({ text, image }) {
  const styles = useStyles()
  return (
    <div className={styles.stayAheadCardDiv}>
      <div className={styles.textDiv}>
        <pre className={styles.text}>{text}</pre>
      </div>
      <img className={styles.cardImg} src={image} alt='' />
    </div>
  )
}
