import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import LogoHeader from './LogoHeader'
import NavCarousel from './NavCarousel'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

import homepage1 from '../../Images/home1.png'
import aboutUs2 from '../../Images/aboutUs2.png'
import products3 from '../../Images/products3.png'
import services4 from '../../Images/services4.png'
import upgrade5 from '../../Images/upgrade5.png'
import contactUs6 from '../../Images/contactUs6.png'
import monitoring7 from '../../Images/monitoring7.png'

import homepage1M from '../../Images/home1M.png'
import aboutUs2M from '../../Images/aboutUs2M.png'
import products3M from '../../Images/products3M.png'
import services4M from '../../Images/services4M.png'
import upgrade5M from '../../Images/upgrade5M.png'
import contactUs6M from '../../Images/contactUs6M.png'
import monitoring7M from '../../Images/monitoring7M.png'

const useStyles = makeStyles((theme) => ({
  mainHome: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 178vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${homepage1M})` : `url(${homepage1})`,
  },
  mainAboutUs: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 178vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${aboutUs2M})` : `url(${aboutUs2})`,
  },
  mainProducts: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 178vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${products3M})` : `url(${products3})`,
  },
  mainServices: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 178vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${services4M})` : `url(${services4})`,
  },
  mainUpgrade: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 178vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${upgrade5M})` : `url(${upgrade5})`,
  },
  mainContactUs: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 180vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${contactUs6M})` : `url(${contactUs6})`,
  },
  mainLiftMonitoring: {
    width: '100%',
    height: isMobile ? '178vw' : '47vw',
    backgroundSize: isMobile ? '100vw 180vw' : '100vw 52vw',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: isMobile ? `url(${monitoring7M})` : `url(${monitoring7})`,
  },
  titleHomeServices: {
    textAlign: 'center',
    marginTop: isMobile ? '' : '4vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingBottom: isMobile ? '26.5vw' : '7vw',
    height: isMobile ? '125vw' : '25vw',
    width: '100%',
    fontWeight: '800',
    fontSize: isMobile ? '12.5vw' : '4vw',
    fontFamily: 'Gothic A1',
    lineHeight: isMobile ? '' : '5vw',
  },
  titleAboutUs: {
    textAlign: 'center',
    marginTop: isMobile ? '' : '4vw',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingBottom: isMobile ? '26.5vw' : '7vw',
    height: isMobile ? '125vw' : '25vw',
    width: '100%',
    fontWeight: '800',
    fontSize: isMobile ? '14.5vw' : '5vw',
    fontFamily: 'Gothic A1',
    lineHeight: isMobile ? '' : '6.5vw',
  },
  titleProducts: {
    textAlign: 'center',
    marginTop: isMobile ? '' : '4vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingBottom: isMobile ? '26.5vw' : '7vw',
    height: isMobile ? '125vw' : '25vw',
    width: '100%',
    fontWeight: '800',
    fontSize: isMobile ? '14.5vw' : '5vw',
    fontFamily: 'Gothic A1',
    flexDirection: 'column',
  },
  titleUpgradeContact: {
    textAlign: 'center',
    marginTop: isMobile ? '43vw' : '4vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingBottom: isMobile ? '26.5vw' : '7vw',
    height: isMobile ? '' : '25vw',
    width: '100%',
    fontWeight: '800',
    fontSize: isMobile ? '11.5vw' : '5vw',
    fontFamily: 'Gothic A1',
    marginBottom: isMobile ? '20vw' : '0vw',
    lineHeight: isMobile ? '' : '6.5vw',
  },
  span: {
    marginTop: isMobile ? ' 6.5vw' : '1.5vw',
    fontWeight: 'normal',
    fontSize: isMobile ? ' 4.5vw' : '1.5vw',
    marginBottom: isMobile ? ' 0vw' : '5vw',
  },
  navCarousel: {
    // marginTop: isMobile ? '' : '3vw',
    minHeight: isMobile ? '24vw' : '',
  },
  header: { marginTop: isMobile ? '4vw' : '2vw' },
}))

export default function Header() {
  const { pathname } = useLocation()

  const [page, setPage] = useState({
    home: false,
    aboutus: false,
    services: false,
    upgrade: false,
    products: false,
    contactus: false,
  })

  useEffect(() => {
    setPage({
      home: pathname.includes('/home'),
      aboutus: pathname.includes('/aboutus'),
      services: pathname.includes('/services'),
      upgrade: pathname.includes('/upgrade'),
      products: pathname.includes('/products'),
      contactus: pathname.includes('/contactus'),
      liftmonitoring: pathname.includes('/liftmonitoring'),
    })
  }, [pathname])

  const classes = useStyles()

  return (
    <div
      className={
        page.home
          ? classes.mainHome
          : page.aboutus
          ? classes.mainAboutUs
          : page.products
          ? classes.mainProducts
          : page.services
          ? classes.mainServices
          : page.upgrade
          ? classes.mainUpgrade
          : page.contactus
          ? classes.mainContactUs
          : classes.mainLiftMonitoring
      }
    >
      <div className={classes.header}>
        {page.home || page.products || page.upgrade ? (
          <LogoHeader variant={'yellow'} />
        ) : (
          <LogoHeader />
        )}
      </div>
      {page.home ? (
        <div className={classes.titleHomeServices}>
          <br />
          BRILLIANT
          <br />
          LIFTS
          <br />
          INCREDIBLE
          <br />
          SERVICE
        </div>
      ) : page.aboutus ? (
        <div className={classes.titleAboutUs}>
          <br />
          LEARN
          <br />
          MORE
          <br />
          ABOUT US
        </div>
      ) : page.products ? (
        <div className={classes.titleProducts}>
          <br />
          BRILLIANT
          <br />
          PRODUCTS
          <br />
          {isMobile ? (
            <span className={classes.span}>
              Brilliant Lifts Australia supplies and <br />
              installs durable lifts and escalators in a <br />
              variety of designs and options to suit most <br />
              residential and commercial applications.
            </span>
          ) : (
            <span className={classes.span}>
              Brilliant Lifts Australia supplies and installs durable lifts and
              <br />
              escalators in a variety of designs and options to suit most
              residential <br />
              and commercial applications.
            </span>
          )}
        </div>
      ) : page.services ? (
        <div className={classes.titleHomeServices}>
          <br />
          BRILLIANT
          <br />
          LIFTS
          <br />
          INCREDIBLE
          <br />
          SERVICE
        </div>
      ) : page.upgrade ? (
        <div className={classes.titleUpgradeContact}>
          UPGRADE
          <br />
          YOUR ASSETS
        </div>
      ) : page.liftmonitoring ? (
        <div className={classes.titleHomeServices}>
          LIFT
          <br />
          MONITORING
          <br />
          ALWAYS
          <br />
          THERE
        </div>
      ) : (
        <div className={classes.titleUpgradeContact}>
          GET IN TOUCH
          <br />
          WITH US!
        </div>
      )}

      <div className={classes.navCarousel}>
        <NavCarousel setPage={setPage} pageMain={page} />
      </div>
    </div>
  )
}
