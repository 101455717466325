import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import BriliantLogoYellow from "../../Images/brilliantLogoY.png";
import brilliantLogoWhite from "../../Images/brilliantLogoW.png";
import mailIconHeader from '../../Images/mailYellow.png'
import phoneIconHeader from '../../Images/phoneYellow.png'
import locationIconHeader from '../../Images/locationYellow.png'
import mailWhite from '../../Images/mailWhite.png'
import phoneWhite from '../../Images/phoneWhite.png'
import locationWhite from '../../Images/locationWhite.png'

import { isMobile } from 'react-device-detect'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  logoHeaderRowD: {
    width: "75.2vw",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: "11.9vw",
    // backgroundColor: 'black'
  },
  logoHeaderRowM: {
    width: "92.1vw",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: "3.86vw",
  },
  brilliantLogoD: {
    width: "11.2vw",
    cursor: "pointer",
    height: "3.8vw",
  },
  brilliantLogoM: {
    width: "44.2vw",
    height: "15vw",
  },
  phoneIconD: {
    width: "1.82vw",
    marginRight: "1.02vw",
  },
  phoneIconM: {
    width: "6.08vw",
    marginLeft: "3.82vw",
  },
  locationIconD: {
    width: "1.82vw",
    marginRight: "2.08vw",
  },
  locationIconM: {
    width: "6.0vw",
    marginLeft: "3.82vw",
  },
  mailIconD: {
    width: "2.2vw",
    marginRight: "2.31vw",
  },
  mailIconM: {
    width: "6.08vw",
    marginLeft: "3.82vw",
  },

  iconsRow: { alignItems: "center", display: "flex" },
  phoneNumber: {
    fontSize: "1.25vw",
    color: "#FA9E23",
    fontWeight: "bold",
  },
}));

export default function LogoHeader(props) {
  const classes = useStyles()
  const history = useHistory()
  const YellowVariant = () => {
    return (
      <div
        className={isMobile ? classes.logoHeaderRowM : classes.logoHeaderRowD}
      >
        <img
          src={BriliantLogoYellow}
          className={isMobile ? classes.brilliantLogoM : classes.brilliantLogoD}
          alt={"logo"}
          onClick={() => history.push({ pathname: "/home", state: "home" })}
        />
        <div className={classes.iconsRow}>
          <img
            src={mailIconHeader}
            className={isMobile ? classes.mailIconM : classes.mailIconD}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({ pathname: "/contactus", state: "contact" })
            }
            alt={"logo"}
          />
          <img
            src={locationIconHeader}
            className={isMobile ? classes.locationIconM : classes.locationIconD}
            style={{ cursor: "pointer" }}
            onClick={() => history.push({ pathname: "/home", state: "loc" })}
            alt={"logo"}
          />
          <img
            src={phoneIconHeader}
            className={isMobile ? classes.phoneIconM : classes.phoneIconD}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({ pathname: "/contactus", state: "contact" })
            }
            alt={"logo"}
          />
          {isMobile ? (
            <div />
          ) : (
            <Typography className={classes.phoneNumber}>
              1300 99 77 10
            </Typography>
          )}{" "}
        </div>
      </div>
    );
  }
  const WhiteVariant = () => {
    return (
      <div
        className={isMobile ? classes.logoHeaderRowM : classes.logoHeaderRowD}
      >
        <img
          src={brilliantLogoWhite}
          className={isMobile ? classes.brilliantLogoM : classes.brilliantLogoD}
          alt={"logo"}
          onClick={() => history.push({ pathname: "/home", state: "home" })}
        />
        <div className={classes.iconsRow}>
          <img
            src={mailWhite}
            className={isMobile ? classes.mailIconM : classes.mailIconD}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({ pathname: "/contactus", state: "contact" })
            }
            alt={"logo"}
          />
          <img
            src={locationWhite}
            className={isMobile ? classes.locationIconM : classes.locationIconD}
            style={{ cursor: "pointer" }}
            onClick={() => history.push({ pathname: "/home", state: "loc" })}
            alt={"logo"}
          />
          <img
            src={phoneWhite}
            className={isMobile ? classes.phoneIconM : classes.phoneIconD}
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push({ pathname: "/contactus", state: "contact" })
            }
            alt={"logo"}
          />
          {isMobile ? (
            <div />
          ) : (
            <Typography
              className={classes.phoneNumber}
              style={{ color: "white" }}
            >
              1300 99 77 10
            </Typography>
          )}{" "}
        </div>
      </div>
    );
  }
  return props.variant === 'yellow' ? <YellowVariant /> : <WhiteVariant />
}
