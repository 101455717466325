import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import brilliantLogoWhite from '../../Images/BrilliantLogoWhite.png';
import footerSocial from '../../Images/FooterSocial.png';
import facebookIconFooter from '../../Images/facebookIconFooter.png';
import instagramIconFooter from '../../Images/instagramIconFooter.png';
import linkedInIconFooter from '../../Images/linkedInIconFooter.png';
import locationIconFooter from '../../Images/locationIconFooter.png';
import mailIconFooter from '../../Images/mailIconFooter.png';
import phoneIconFooter from '../../Images/phoneIconFooter.png';
import upIconFooter from '../../Images/upIconFooter.gif';
import facebookIconFooter1 from '../../Images/facebookIconFooter1.png';
import instagramIconFooter1 from '../../Images/instagramIconFooter1.png';
import linkedInIconFooter1 from '../../Images/linkedInIconFooter1.png';
import locationIconFooter1 from '../../Images/locationIconFooter1.png';
import mailIconFooter1 from '../../Images/mailIconFooter1.png';
import phoneIconFooter1 from '../../Images/phoneIconFooter1.png';
import ellipse from '../../Images/Ellipse2.png';
import line from '../../Images/Line.png';
import { useHistory } from 'react-router-dom';
import { Data } from '../../Data/General/Footer';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    backgroundColor: '#FA9E23',
    height: isMobile ? '62vw' : '14vw',
    bottom: 0,
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    bottom: isMobile ? '27vw' : '4.5vw',
    left: isMobile ? '13vw' : '11.7vw',
    width: isMobile ? '75vw' : '23.5vw',
  },

  copyWriting: {
    color: 'white',
    fontWeight: '500',
    fontSize: isMobile ? '2.52vw' : '0.77vw',
    fontFamily: 'Gothic A1',
    position: 'absolute',
    bottom: isMobile ? '20vw' : '1.5vw',
    left: isMobile ? '15.4vw' : '12.5vw',
    width: isMobile ? '75vw' : '23.5vw',
  },
  footerSocial: {
    position: 'absolute',
    bottom: '0',
    right: '11.5vw',
    height: '14vw',
  },
  iconUp: {
    height: isMobile ? '12vw' : '2.2vw',
    position: 'absolute',
    bottom: isMobile ? '56.5vw' : '10vw',
    right: isMobile ? '46.81vw' : '20vw',
    cursor: 'pointer',
  },
  iconMail: {
    height: isMobile ? '8vw' : '1vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '6.5vw',
    right: isMobile ? '52.8vw' : '11.8vw',
    // cursor: 'pointer',
  },
  iconPhone: {
    height: isMobile ? '8vw' : '1.5vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '6.25vw',
    right: isMobile ? '64.8vw' : '16.1vw',
    // cursor: 'pointer',
  },
  iconFacebook: {
    height: isMobile ? '8vw' : '1.45vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '2.2vw',
    right: isMobile ? '40.8vw' : '20.35vw',
    // cursor: 'pointer',
  },
  iconLinkedIn: {
    height: isMobile ? '8vw' : '1.35vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '2.3vw',
    right: isMobile ? '16vw' : '11.93vw',
    // cursor: 'pointer',
  },
  iconLocation: {
    height: isMobile ? '8vw' : '1.55vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '6.25vw',
    right: isMobile ? '77vw' : '20.15vw',
    // cursor: 'pointer',
  },
  iconInstagram: {
    height: isMobile ? '8vw' : '1.6vw',
    position: 'absolute',
    bottom: isMobile ? '3.7vw' : '2.15vw',
    right: isMobile ? '28.8vw' : '15.88vw',
    // cursor: 'pointer',
  },
  line: {
    width: '100%',
    position: 'absolute',
    bottom: '7vw',
    left: 0,
  },
  ellipse: {
    cursor: 'pointer',

    width: '13vw',
    position: 'absolute',
    bottom: '55.5vw',
    left: '43vw',
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.main}>
      {isMobile ? (
        <div>
          <img className={classes.logo} src={brilliantLogoWhite} alt='logo' />
          <div className={classes.copyWriting}>{Data.body}</div>
          <img className={classes.line} src={line} alt='logo' />

          <img
            className={classes.iconMail}
            src={mailIconFooter1}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push({ pathname: '/contactus', state: 'contact' })
            }
          />
          <img
            className={classes.iconPhone}
            src={phoneIconFooter1}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push({ pathname: '/contactus', state: 'contact' })
            }
          />
          <img
            className={classes.iconFacebook}
            src={facebookIconFooter1}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />
          <img
            className={classes.iconLinkedIn}
            src={linkedInIconFooter1}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />
          <img
            className={classes.iconLocation}
            src={locationIconFooter1}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() => history.push({ pathname: '/home', state: 'map' })}
          />
          <img
            className={classes.iconInstagram}
            src={instagramIconFooter1}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />
          <img
            className={classes.ellipse}
            src={ellipse}
            alt='icon'
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
          <img
            className={classes.iconUp}
            src={upIconFooter}
            alt='icon'
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
        </div>
      ) : (
        <div>
          <img className={classes.logo} src={brilliantLogoWhite} alt='logo' />
          <img className={classes.footerSocial} src={footerSocial} alt='logo' />
          <img
            className={classes.iconUp}
            src={upIconFooter}
            alt='icon'
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          />
          <img
            className={classes.iconMail}
            src={mailIconFooter}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push({ pathname: '/contactus', state: 'contact' })
            }
          />
          <img
            className={classes.iconPhone}
            src={phoneIconFooter}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() =>
              history.push({ pathname: '/contactus', state: 'contact' })
            }
          />
          <img
            className={classes.iconFacebook}
            src={facebookIconFooter}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />
          <img
            className={classes.iconLinkedIn}
            src={linkedInIconFooter}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />
          <img
            className={classes.iconLocation}
            src={locationIconFooter}
            alt='icon'
            style={{ cursor: 'pointer' }}
            onClick={() => history.push({ pathname: '/home', state: 'map' })}
          />
          <img
            className={classes.iconInstagram}
            src={instagramIconFooter}
            alt='icon'
            // onClick={() =>
            //   window.open(
            //     'https://www.instagram.com/thebusinesshub.space/?hl=en'
            //   )
            // }
          />

          <div className={classes.copyWriting}>{Data.body}</div>
        </div>
      )}
    </div>
  );
}
