import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import WhereToMapWeb from '../../Images/mapW.png';
import WhereToMapMobile from '../../Images/mapM.png';
import iconLoc from '../../Images/iconLoc.png';
import { isMobile } from 'react-device-detect';
const useStyles = makeStyles((theme) => ({
  mainDiv: {
    width: isMobile ? '92vw' : '75vw',
    // paddingLeft: isMobile ? '3vw' : '12.5vw',
    // paddingRight: isMobile ? '3vw' : '12.5vw',
    fontSize: isMobile ? '8vw' : '3vw',
    fontFamily: 'Gothic A1',
    color: '#FA9E23',
    textAlign: 'left',
    position: 'relative',
    fontWeight: '900',
  },
  titleDiv: {
    width: isMobile ? '92vw' : '75vw',
  },
  mapImg: {
    width: isMobile ? '92vw' : '75vw',
    borderRadius: '1vw',
    marginTop: isMobile ? '6vw' : '2.5vw',
  },
  locIcon2: {
    width: isMobile ? '8vw' : '2.3vw',
    height: isMobile ? '11vw' : '3.4vw',
    top: isMobile ? '107vw' : '40.3vw',
    left: isMobile ? '2.2vw' : '15.35vw',
    position: 'absolute',
    cursor: 'pointer',
  },
  locIcon1: {
    width: isMobile ? '8vw' : '2.3vw',
    height: isMobile ? '11vw' : '3.4vw',
    top: isMobile ? '54vw' : '14.7vw',
    left: isMobile ? '64.2vw' : '45.42vw',
    position: 'absolute',
    cursor: 'pointer',
  },
}));
export default function WhereToMap() {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <div className={classes.titleDiv}>WHERE TO </div>
      <div className={classes.titleDiv}>FIND BRILLIANT </div>

      <img
        className={classes.mapImg}
        src={isMobile ? WhereToMapMobile : WhereToMapWeb}
        alt='logo'
      ></img>

      <Tooltip title='Click to open in google maps'>
        <img
          className={classes.locIcon1}
          src={iconLoc}
          alt='logo'
          onClick={() => window.open('https://goo.gl/maps/ytBtU28ph1q2ZdP78 ')}
        />
      </Tooltip>
    </div>
  );
}
