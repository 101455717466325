import './App.css'
import React from 'react'

import Header from './Components/General/Header'
import Footer from './Components/General/Footer'
import Home from './Pages/Home'
import AboutUs from './Pages/AboutUs'
import ContactUs from './Pages/ContactUs'
import Services from './Pages/Services'
import Upgrade from './Pages/Upgrade'
import Products from './Pages/Products'
import LiftMonitoring from './Pages/LiftMonitoring'

import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'

function App() {
  return (
    <div>
      <Router>
        <React.Fragment>
          <Route
            path='/aboutus'
            exact
            render={() => (
              <div>
                <Header /> <AboutUs />
              </div>
            )}
          />
          <Route
            path='/home'
            exact
            render={() => (
              <div>
                <Header /> <Home />
              </div>
            )}
          />
          <Route
            path='/services'
            exact
            render={() => (
              <div>
                <Header />
                <Services />
              </div>
            )}
          />
          <Route
            path='/upgrade'
            exact
            render={() => (
              <div>
                <Header />
                <Upgrade />
              </div>
            )}
          />
          <Route
            path='/products'
            exact
            render={() => (
              <div>
                <Header />
                <Products />
              </div>
            )}
          />
          <Route
            path='/contactus'
            exact
            render={() => (
              <div>
                <Header />
                <ContactUs />
              </div>
            )}
          />
          <Route
            path='/liftmonitoring'
            exact
            render={() => (
              <div>
                <Header />
                <LiftMonitoring />
              </div>
            )}
          />
          <Route path='/' exact render={() => <div></div>}>
            <Redirect to='/home' />
          </Route>
          <Footer />
        </React.Fragment>
      </Router>
    </div>
  )
}
export default App
