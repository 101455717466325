import React from 'react';
import CommercialCarrousel from '../Components/Products/CommercialElevatorsCarrousel';
import EscalatorsCarrousel from '../Components/Products/EscalatorsCarrousel';
import ResidentialCarrousel from '../Components/Products/ResidentialElevatorsCarrousel';
import Lifts from '../Components/Products/LiftsElevators';
import PerformancePortfolio from '../Components/Products/PerformancePortfolio';
import GetInTouch from '../Components/Products/GetInTouch';
import Escalators from '../Components/Products/Escalators';
import Travelators from '../Components/Products/Travelators';
import TravelatorsCarrousel from '../Components/Products/TravelatorsCarrousel';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  carousel: {
    minHeight: isMobile ? '218vw' : 'unset',
    paddingTop: isMobile ? '12vw' : '1vw',
    paddingLeft: isMobile ? '0vw' : '7.5vw',
  },
  Lifts: {
    minHeight: isMobile ? '30vw' : 'unset',
    paddingTop: isMobile ? '9vw' : '7vw',
  },
  Escalators: {
    minHeight: isMobile ? '26vw' : 'unset',
    paddingTop: isMobile ? '0vw' : 'unset',
  },
  Travelators: {
    minHeight: isMobile ? '26vw' : 'unset',
    paddingTop: isMobile ? '0vw' : 'unset',
  },
  LineDivider: {
    borderBottom: '2px solid #E5E5E5',
    width: isMobile ? '92vw' : '75vw',
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
    marginTop: isMobile ? '21vw' : '8vw',
    marginBottom: isMobile ? '14vw' : '8vw',
    padding: 0,
  },
  PerformancePortfolio: {
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
    marginTop: isMobile ? '14vw' : '8vw',
    marginBottom: isMobile ? '14vw' : '8vw',
  },
});

export default function Products() {
  const classes = useStyles();
  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className={classes.Lifts}>
        <Lifts />
      </div>
      <div className={classes.carousel}>
        <CommercialCarrousel />
      </div>
      <div className={classes.carousel}>
        <ResidentialCarrousel />
      </div>
      <div className={classes.LineDivider} />
      <div className={classes.Escalators}>
        <Escalators />
      </div>
      <div className={classes.carousel}>
        <EscalatorsCarrousel />
      </div>
      <div className={classes.LineDivider} />
      <div className={classes.Travelators}>
        <Travelators />
      </div>
      <div className={classes.carousel}>
        <TravelatorsCarrousel />
      </div>
      <div className={classes.LineDivider} />
      <div className={classes.PerformancePortfolio}>
        <PerformancePortfolio />
      </div>
      <div>
        <GetInTouch />
      </div>
    </div>
  );
}
