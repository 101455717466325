import React from 'react'
import { Data } from '../../Data/Home/welcome.json'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  Text1: {
    color: '#FA9E23',
    fontSize: isMobile ? '7.5vw' : '3vw',
    fontWeight: 'bolder',
    textAlign: 'center',
    fontFamily: 'Gothic A1',
  },
  Text2: {
    padding: isMobile ? '3vw' : '',
    color: '#4B4B4B',
    fontSize: isMobile ? '4.5vw' : '1.65vw',
    fontWeight: 'normal',
    textAlign: 'center;',
    fontFamily: 'Gothic A1',
    marginTop: isMobile ? '-1vw' : '2vw',
  },
})

export default function Welcome() {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.Text1}>
        {Data.map((post) => {
          return (
            <div>
              <p style={{ alignContent: 'center' }}>
                <p className={classes.Text1}>
                  {post.Title1}
                  <br />
                  {post.Title2}
                </p>

                <p className={classes.Text2}>
                  {isMobile ? post.Body1 + ' ' + post.Body2 : post.Body1}
                  <br />
                  {isMobile ? '' : post.Body2}
                  {isMobile ? '' : <br />}
                  {/* <br />
                  {post.Body3} */}
                </p>
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
