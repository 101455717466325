import React, { useState } from 'react'
import ContactInfo from './ContactInfo'
import Form from './Form'
import { isMobile } from 'react-device-detect'
import { makeStyles } from '@material-ui/core/styles'

export default function ContactIntegeration() {
  const useStyles = makeStyles({
    root: {
      display: isMobile ? '' : 'flex',
      paddingLeft: isMobile ? '4.25vw' : '',
    },

    circle: {
      position: 'absolute',
      width: isMobile ? '25.5vw' : '7.4vw',
      height: isMobile ? '25.5vw' : '7.4vw',
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      marginTop: isMobile ? '179vw' : '19vw',
      marginLeft: isMobile ? '34vw' : '46.45vw',
    },

    smallCircle: {
      background: isMobile
        ? 'linear-gradient( 0deg, #FA9E23 51%,  #4B4B4B 51% )'
        : 'linear-gradient( -90deg, #FA9E23 51%,  #4B4B4B 51% )',
      marginTop: isMobile ? '181vw' : '19.5vw',
      marginLeft: isMobile ? '35.7vw' : '46.85vw',
      position: 'absolute',
      width: isMobile ? '22vw' : '6.4vw',
      height: isMobile ? '22vw' : '6.4vw',
      backgroundColor: '#e5e5e5',
      borderRadius: '50%',
      zIndex: '1',
      transform: isMobile ? 'rotateX(0deg)' : 'rotateY(0deg)',
      transition: 'transform 1s',
      cursor: 'pointer',
    },
    smallCircle2: {
      background: isMobile
        ? 'linear-gradient( 0deg, #FA9E23 51%,  #4B4B4B 51% )'
        : 'linear-gradient( -90deg, #FA9E23 51%,  #4B4B4B 51% )',
      marginTop: isMobile ? '181vw' : '19.5vw',
      marginLeft: isMobile ? '35.7vw' : '46.85vw',
      position: 'absolute',
      width: isMobile ? '22vw' : '6.4vw',
      height: isMobile ? '22vw' : '6.4vw',
      backgroundColor: '#e5e5e5',
      borderRadius: '50%',
      zIndex: '1',
      transform: isMobile ? 'rotateX(-180deg)' : 'rotateY(-180deg)',
      transition: 'transform 1s',
      cursor: 'pointer',
    },
  })
  const [colorSwitch, setColorSwitch] = useState(true)
  const styles = useStyles()
  const changeColor = () => {
    if (
      document.getElementById('circle').className.substring(11, 23) ===
      'smallCircle2'
    ) {
      document.getElementById('circle').className = styles.smallCircle
      setColorSwitch(!colorSwitch)
    } else {
      document.getElementById('circle').className = styles.smallCircle2
      setColorSwitch(!colorSwitch)
    }
  }
  return (
    <div className={styles.root}>
      <div
        id='circle'
        className={colorSwitch ? styles.smallCircle : styles.smallCircle2}
        onClick={changeColor}
      ></div>
      <div className={styles.circle}></div>

      <ContactInfo dark={!colorSwitch} />
      <Form isDark={colorSwitch} />
    </div>
  )
}
