import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import ContactUsNow from '../Components/LiftMonitoring/ContactUsNow'
import BeingStuck from '../Components/LiftMonitoring/BeingStuck'
import { useHistory } from 'react-router-dom'
import WirelessCommunicationGateway from '../Components/LiftMonitoring/WirlessCommunicationGateway'
import ReportEvents from '../Components/LiftMonitoring/ReportEvents'
import Features from '../Components/LiftMonitoring/Features'
import Communication from '../Components/LiftMonitoring/Communication'
import SwitchProduct from '../Components/LiftMonitoring/SwitchProduct'

const useStyles = makeStyles({
  main: {
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
  },
  LearnMore: { marginTop: isMobile ? '25vw' : '8vw' },
  WirelessCommunicationGateway: { marginTop: isMobile ? '10vw' : '5vw' },
  ContactUsNow: { marginTop: isMobile ? '20vw' : '8vw' },
  CommunicationFeaturesDiv: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    marginTop: isMobile ? '3vw' : '5vw',
  },
})

export default function LiftMonitoring() {
  const classes = useStyles()
  const [product, setProduct] = useState('SingleLine')
  return (
    <div style={{ overflow: 'hidden' }}>
      <div className={classes.main}>
        <div className={classes.WirelessCommunicationGateway}>
          <WirelessCommunicationGateway />
        </div>
        <div className={classes.QualityCards} id='quality-cards'>
          <BeingStuck />
        </div>
        <div className={classes.ReportEvents}>
          <ReportEvents />
        </div>
        <div className={classes.SwitchProduct}>
          <SwitchProduct product={product} setProduct={setProduct} />
        </div>
        <div className={classes.CommunicationFeaturesDiv}>
          <div className={classes.Communication}>
            <Communication product={product} />
          </div>
          <div className={classes.Features}>
            <Features product={product} />
          </div>
        </div>
      </div>
      <div className={classes.ContactUsNow}>
        <ContactUsNow />
      </div>
    </div>
  )
}
