import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router-dom'
import GetToKnow from '../Components/Home/GetToKnow'
import FindOutMore from '../Components/Home/FindOutMore'
import Glance from '../Components/Home/Glance'
import Welcome from '../Components/Home/Welcome'
import Shareholder from '../Components/Home/Shareholder'
import WhereToMap from '../Components/Home/WhereToMap'
import MaintenanceCarousel from '../Components/Home/MaintenanceCarousel'
// import Testimonials from '../Components/Home/Testimonials';

const useStyles = makeStyles({
  main: {
    marginLeft: isMobile ? '4vw' : '12.5vw',
    marginRight: isMobile ? '4vw' : '12.5vw',
  },
  welcome: { marginTop: isMobile ? '20vw' : '3.5vw' },
  shareholder: { marginTop: isMobile ? '20vw' : '8.5vw' },
  gettoknow: { marginTop: isMobile ? '15vw' : '12vw' },
  glace: { marginTop: isMobile ? '20vw' : '3.5vw' },
  wheretomap: {
    marginTop: isMobile ? '25vw' : '6vw',
    marginBottom: isMobile ? '25vw' : '7vw',
  },
  testimonials: { marginTop: isMobile ? '25vw' : '8vw' },
})

export default function Home() {
  const classes = useStyles()
  const history = useHistory()
  useEffect(() => {
    const element = document.getElementById('map')
    if (element) {
      if (history.location.state === 'map') {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        // console.log('NICE');
        // window.scrollTo(0, 0);
        // window.scrollTo(0, 3500);
      }
    }
  })

  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className={classes.main}>
        <div className={classes.welcome}>
          <Welcome />
        </div>
        <div className={classes.shareholder}>
          <Shareholder />
        </div>
        <div className={classes.gettoknow}>
          <GetToKnow />
        </div>
        <div className={classes.glace}>
          <Glance />
        </div>
        <MaintenanceCarousel />
        {/* <div className={classes.testimonials}>
          <Testimonials />
        </div> */}
        <div className={classes.wheretomap} id='map'>
          <WhereToMap />
        </div>
      </div>

      <FindOutMore />
    </div>
  )
}
